.batch-actions {
    position: fixed;
    right: -110px;
    bottom: 10%;
    width: 100px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    transition: right 0.5s cubic-bezier(0.4, 0.0, 0.2, 1);;
    padding: 14px 0 20px;
    text-align: center;
    white-space: normal;
    z-index: 1;

    &.visible {
        right: 0;
    }

    &__header {
        border-bottom: 1px solid rgba(157, 157, 157, 0.5);
        padding: 0 10px 10px;

        h4 {
            font-size: 12px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.2);
            line-height: 1.33;
            margin-bottom: 10px;
        }

        .users-count {
            font-size: 26px;
            line-height: 34px;
            font-weight: 600;
            color: #000000;
        }

        span {
            font-size: 12px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.5);
            display: block;
            line-height: 1.33;
        }
    }

    &__buttons {
        padding-top: 8px;

        button {
            border: none;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            font-size: 12px;
            height: 75px;
            clear: bottom;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.5);
            padding: 15px 0;
            background-repeat: no-repeat;
            margin: 0 9px 13px;
            width: calc(100% - 18px);
            background-size: 24px;
            background-position: center 15px;

            &.progress-button {
                background-image:  url("../img/batchIcons/progress.svg");
            }

            &.reject-button {
                background-image:  url("../img/batchIcons/reject.svg");
            }

            &.extend-button {
                background-image:  url("../img/batchIcons/extend.svg");
            }

            &.message-button {
                background-image:  url("../img/batchIcons/message.svg");
            }

            &:disabled.message-button {
                 background-image:  url("../img/batchIcons/message_inactive.svg");
            }

            img {
                margin-bottom: 8px;
            }

            &:hover:not(:disabled), &:active:not(:disabled) {
                background-color: #f4f4f4;
            }
        }
    }

    @media screen and (max-width:772px) {
        display: none;
    }
}

.simple-popup {
    &__content {
        max-width: 600px !important;
        width: 100% !important;
        > div {
            border-radius: 6px !important;
            box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1) !important;
        }
    }

    &__body {
        padding: 0 !important;
    }

    &__body-inner {
        padding: 35px 15px 0px;
        color: #4a4a4a;

        @include bp(tab) {
            padding: 35px 30px 0px;
        }
    }

    &__header {
        font-size: 24px;
        font-weight: bold;
        line-height: 21px;
        margin-bottom: 28px;
        color: rgba(0, 0, 0, 0.8);

        &.ai-header {
            line-height: normal;
            color: #606062;
            padding-right: 50px;
        }
    }

    &__close {
        position: absolute;
        top: 4px;
        right: 0;
        border: 0;
        width: 21px;
        height: 21px;
        background: transparent url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiI+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBpZD0iYSIgZD0iTTcuMDUxIDZMMTIgMTAuOTQ5IDEwLjk0OSAxMiA2IDcuMDUxIDEuMDUxIDEyIDAgMTAuOTQ5IDQuOTQ5IDYgMCAxLjA1MSAxLjA1MSAwIDYgNC45NDkgMTAuOTQ5IDAgMTIgMS4wNTEgNy4wNTEgNnoiLz4KICAgIDwvZGVmcz4KICAgIDx1c2UgZmlsbD0iIzAwMCIgZmlsbC1vcGFjaXR5PSIuNCIgZmlsbC1ydWxlPSJldmVub2RkIiB4bGluazpocmVmPSIjYSIvPgo8L3N2Zz4K') no-repeat 50% 50%;
        background-size: 16px;
    }

    &__section {
        position: relative;
    }


    &__description {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 28px;
        max-width: 500px;
        line-height: 1.38;

        &.simple {
            max-width: 100%;
        }

        p {
            margin: 0 0 20px;
        }

        span {
            font-weight: bold;
        }
    }

    &__error {
        font-size: 12px;
        line-height: 1.5;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 30px;
        max-width: 450px;
        text-transform: uppercase;
        padding-left: 26px;
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBpZD0iYSIgZD0iTTggMTZBOCA4IDAgMSAxIDggMGE4IDggMCAwIDEgMCAxNnptMC0xLjMzM0E2LjY2NyA2LjY2NyAwIDEgMCA4IDEuMzMzYTYuNjY3IDYuNjY3IDAgMCAwIDAgMTMuMzM0ek03LjMzMyAzLjMzM2gxLjMzNFYxMEg3LjMzM1YzLjMzM3ptMCA4aDEuMzM0djEuMzM0SDcuMzMzdi0xLjMzNHoiLz4KICAgIDwvZGVmcz4KICAgIDx1c2UgZmlsbD0iI0ZGNUU1RSIgZmlsbC1ydWxlPSJldmVub2RkIiB4bGluazpocmVmPSIjYSIvPgo8L3N2Zz4K') 0px 2px no-repeat;
    }

    &__buttons {
        background-color: rgba(243, 243, 243, 0.74);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 22px 30px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
    }
}
