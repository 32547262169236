.tooltip {
    font-family: 'Montserrat', sans-serif;
    text-transform: initial;
    white-space: pre-line;
    font-size: 14px;
    padding: 10px 15px;
    @extend %u-rounded;

    .tooltip-inner {
        @extend %u-rounded;
    }
}

.c-header-main {
    .tooltip {
        .tooltip-inner {
            padding: 3px 25px;
            text-align: center;
        }
    }
}

.c-tooltip {
    font-size: 10px;
    color: #fff !important;
    padding: 2px 10px !important;
    background-color: $blue !important;
    max-width: 200px;

    &.place-top:after {
        border-top-color: $blue !important;
    }

    &.place-bottom:after {
        border-bottom-color: $blue !important;
    }
}

.c-tooltip_white {
    width: 265px;
    font-family: $font-stack---open-sans;
    padding: 20px 25px;
    border-radius: 7px;
    background-color: #FFFFFF;
    color: #3a3c43;
    text-align: left;
    pointer-events: auto !important;
    font-weight: 400;
    font-size: 14px;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.25);
    white-space: normal;
    line-height: 1.5;
    z-index: 10000 !important;
    &.show {
        opacity: 1 !important;
    }
    &:hover {
        visibility: visible !important;
        opacity: 1 !important;
    }
}

.c-tooltip_dark {
    background-color: #00283f !important;
    border-radius: 2px !important;
    opacity: 0.89 !important;
    font-size: 10px !important;
    color: rgba(255, 255, 255, 0.87) !important;
    line-height: 1.4;
    padding: 0 !important;
    position: absolute !important;
    top: 5px !important;
    left: 6px !important;
    text-align: center;
    width: 60px;

    &::after {
        border-top-color: #00283f !important;
        margin-left: 4px !important;
        border-left: 4px solid transparent !important;
        border-right: 4px solid transparent !important;
        border-top-width: 4px !important;
        bottom: -3px !important;
        margin-left: -4px !important;
    }
}

