//  ------------------------------------
//  #Width sizes
//  ------------------------------------
// example: u-wdt--10

$width-sizes: ( 10, 20, 36, 42, 50, 60, 65, 70, 75, 100, 110, 115, 118, 121, 130, 150, 160, 172, 200, 225, 260, 270 );
@each $name in $width-sizes {
    %u-wdt--#{$name},
    .u-wdt--#{$name} {width: #{$name}px !important;}
}

%u-wdt--100p, .u-wdt--100p { width: 100% !important; }
%u-wdt--auto, .u-wdt--auto { width: auto !important; }

