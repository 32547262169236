/*------------------------------------*\
    #Columns on opportunities page
\*------------------------------------*/

.c-board {
    position: relative;
    padding: 0;
    z-index: 1;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

 /*title*/
.c-board--header__title {
    font-family: $font-stack---open-sans;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #3a3c43;
    margin-bottom: 12px;
}

.c-col {
    display: inline-block;
    vertical-align: top;
    padding: 0 24px 0 24px;
    width: 334px;
    white-space: normal;
    overflow: visible;

    &:first-child {
        .c-col__header {
            margin-left: 0;
            padding-left: 0;
        }
    }

    /* Auto-progress column */
    &--merged {
        border-right: 0;
        position: relative;
        &:after {
            content: '\e953';
            font-size: 11px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 98px;
            right: -16px;
            height: 32px;
            width: 32px;
            border: 1px solid #DDDDDD;
            background-color: #f5f5f5;
            border-radius: 50%;
            z-index: 1;
            color: $black-63;
        }
    }

    /* inner */
    &__inner {
        position: relative;
        background: #fff;
        padding: 24px 10px 12px;
        border-bottom: 1px solid rgba(#000, .1);

        &:first-child {padding: 10px 10px 16px;}
        &:last-child {border-bottom: 0;}

        &--txt {
            color: $blue;
            display: block;
            margin-top: 12px;
            text-align: center;
        }
    }

    &__empty {
        border-right: 0;
        width: 334px;

        &--title {
            width: 100%;
            display: flex;
            color: #3a3c43;
            margin-top: 29px;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            height: 50px;
            border-radius: 4px;
            border: dashed 1px #979797;
            background-color: #ebebeb;
            transition: all 0.3s;
            &:hover:not(:disabled) {
                color: $brand;
                font-weight: 700;
                border-color: $brand;
                background-color: rgba(0, 160, 252, 0.13);
            }
            &:disabled {
                opacity: 0.5;
            }
        }
        &--offline-title {
            font-size: 10px;
            margin-top: 24px;
            color: rgba(0, 0, 0, 0.5);
            text-align: center;
        }
    }

    /* Column header */
    &__header {
        color: $black-63;
        display: flex;
        flex-direction: column;
        padding: 5px 24px 6px;
        position: relative;
        margin: 0 -24px 0 -24px;
    }

    /* Column title */
    &__title {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 14px;
        min-height: 14px;
    }

    /* Column badge */
    &__badge-holder {
        padding: 15px 0;
        display: flex;
        color: rgba(0,0,0,0.5);
        line-height: 1;
        font-size: 12px;
        font-weight: 500;
        align-items: center;
        min-height: 44px;
        -ms-flex-pack: start;
        justify-content: space-between;
    }
}

/* D&D react plugin */
.c-dropzone {
    padding: 0 24px 30px;
    min-height: 96px;
    transition: .2s ease;
    margin: 0 -24px;

    &--is-dropping {
        background-color: rgba(0,0,0,0.03);
    }

    &__scroll-holder {
        margin-right: -24px;
        margin-left: -24px;
    }

    &__scroll {
        background: rgba($grey-dark, .2);
        border-radius: 50em;
        margin-left: -6px;
    }
}
.c-droppzone__item {
    font-family: $font-stack---open-sans;
    position: relative;
    transition: .2s ease;
    box-shadow: 0px 0px 40px rgba(41, 42, 45, 0.15);
    width: 285px;
    margin-left: 24px;
    border-radius: 4px;
    border-left: 8px solid white;
    color: #00283f;
    overflow: visible;

    &_inactive {
        border-left: 8px solid transparent;
        background-color: #fff;
        opacity: 0.5;

        button {
            width: 100%;
        }
    }

    &:not(:first-child) {
        margin-top: 15px;
    }

    &::after {
        content: 'test';
        position: absolute;
        top: 7px;
        left: -25px;
        width: 65px;
        transform: rotate(315deg);
        color: #FFFFFF;
        font-size: 11px;
        font-weight: 500;
        background: $blue;
        text-align: center;
        clip-path: polygon(32% 0, 69% 0, 92% 100%, 9% 100%);
    }

    &.no-test-label {
        &::after {
            height: 0;
        }
    }

    &[draggable="true"]:not(.batch):hover {
        box-shadow: 0 8px 17px 0 rgba(0,0,0,0.20), 0 6px 20px 0 rgba(0,0,0,0.19);
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        z-index: 1;
    }

    &:hover {
        .c-card__icon-star-hidden {
            opacity: 1;
        }

        .c-card__link {
            opacity: 1;
        }
    }

    .c-card__icon-star-hidden {
        opacity: 0;
    }

    .c-card__star {
        font-size: 18px;
        transition: all 0.2s;
    }

    .c-card__star-wrapper {
        position: absolute;
        right: 0;
        top: 4px;
        z-index: 1;
        &:hover {
            .c-card__star {
                font-size: 19px;
            }
        }
    }

    .c-card__link {
        position: absolute;
        opacity: 0;
        transition: all 0.2s;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
        outline: none;
        border: none;
        bottom: 8px;
        padding: 0 !important;
        &:hover {
            font-weight: 600;
        }
        &_reject {
            left: 13px;
            min-width: auto;
            color: #ff5e5e;
        }
        &_grade {
            left: 75px;
            color: #1247F6;
        }
    }
}

/* special cases: */
.c-droppzone__item.is-dragging {
    box-shadow: 0 25px 55px 0 rgba(0,0,0,0.19), 0 16px 28px 0 rgba(0,0,0,0.24);
}
.c-heading-interview + .c-droppzone__item,
.c-heading-interview + .c-card {margin-top: 24px;}

.c-questions-list-item {
    display: flex;
    justify-content: flex-start;
    background: white;
    position: relative;
    padding: 12px 25px 12px 20px;
    line-height: 24px;
    font-size: 14px;
    &:not(:last-child) {
        border-bottom: 1px solid #EBECEE;
    }

    &__text-wrapper {
        white-space: nowrap;
        overflow: hidden;
        color: #1d2530;
        text-overflow: ellipsis;

        &--empty {
            @media only screen and (max-width: 991px) {
                white-space: normal;
            }
        }
    }
}
