/*------------------------------------
    #Settings layout
------------------------------------*/
.c-settings {
    &__addlink {
        font-weight: 500;
        margin-top: 24px;
        text-align: center;
        a {
            color: $blue;
        }
        i {
            font-size: 10px;
            margin-right: 10px;
        }
    }

    &__warning {
        display: flex;
        margin-bottom: 30px;
        max-width: 550px;
        letter-spacing: 0.2px;

        img {
            height: 37px;
            padding: 0 15px 5px 0;
        }
    }

    &__additional-info {
        padding: 40px;
        border-radius: 6px;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: 0.2px;
        background: white url("../img/pageup/plug-n-play.png") no-repeat right bottom;
        background-size: 310px 290px;

        @media only screen and (max-width: 1200px) {
            background: white;
        }

        &-header {
            font-size: 20px;
            font-weight: bold;
            line-height: 1.8;
            letter-spacing: normal;
            color: #436cff;
            margin-bottom: 15px;
        }

        &-list {
            margin-left: 45px;
            margin-bottom: 25px;
            li {
                list-style: disc outside none;
            }
        }

        hr {
            border-color: #ededed;
        }
    }

    &__input-wrapper {
        max-width: 590px;
    }

    &__pageup-tooltip {
        max-width: 240px;
    }

    &__pageup-settings {
        padding: 40px;
        @media only screen and (max-width: 767px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
