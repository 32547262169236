@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-layer-width                     : 30px !default;
$hamburger-layer-height                    : 3px !default;
$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : $black-87 !default;
$hamburger-layer-border-radius             : 3px !default;
$hamburger-hover-opacity                   : 0.7 !default;
$hamburger-hover-transition-duration       : .075s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;



// Hamburger
// ==================================================
.hamburger {
  display: inline-block;
  cursor: pointer;
  float: right;
  padding: 0;

  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }
    @else {
      opacity: $hamburger-hover-opacity;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

.hamburger--spring {
  .hamburger-inner {
    top: $hamburger-layer-height / 2;
    transition: background-color 0s 0.15s linear;

    &::before {
      top: $hamburger-layer-height + $hamburger-layer-spacing;
      transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                  transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &::after {
      top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
      transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                  transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-inner {
      transition-delay: 0.32s;
      background-color: transparent;

      &::before {
        top: 0;
        transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                    transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(45deg);
      }

      &::after {
        top: 0;
        transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                    transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(-45deg);
      }
    }
  }
}
