//  ----------------------------------------
//  #Custom grid
//  ----------------------------------------
.row {
    &.row-15 {
        margin-right: -15px;
        margin-left: -15px;

        //to avoid overriding when there is another custom grid before this one(in time all .col classes should be replaced by .col-8, etc.)
        .col-15 {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.container-sm {
    max-width: 770px;
}