/*------------------------------------*\
    #Layout
\*------------------------------------*/

/**
 *  Bootstrap override
 */
@media only screen and (max-width: 768px) {
    .container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.container {
    &--shadow {
        margin: 0 auto;
        padding: 0;
        width: 100%;
        max-width: 800px;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
        background: #fff;
    }
    &--shadow--padding {
        padding-top: 8vh;
        padding-bottom: 8vh;
    }
}

.o-box {
    padding: 20px;
    border-radius: $base-border-radius;
    display: block;

    > :first-child {margin-top: 0;}
    > :last-child  {margin-bottom: 0;}

    /* padding variations */
    &--sm {
        padding: 24px;
    }
    &--md {
        padding: 20px;
        @include bp(tab) {
            padding: 30px;
        }
    }

    &--lg {
        padding: 20px;
        @include bp(tab) {
            padding: 24px 40px 80px;
        }
    }

    /* color variations */
    &--white {
        background: #fff;
    }

    &--whitefull {
        background: #fff;
        color: $grey-dark;
    }
    &--blue {
        padding: 24px;
        background: $blue;
        color: #fff;
        margin-top: -24px;
        margin-left: -24px;
        margin-right: -24px;
        border-radius: 0;
    }
    &--dimmed {
        background: #F8FAFB;
    }


    &--shadow {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
    }

    &--border {
        border: 2px solid #EBECEE;
    }

    &.c-img-upload-box {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.o-box-grey {
    background: rgba($grey-60, .2);
    padding: 20px 30px;
}



/*------------------------------------*\
    #lost grid
\*------------------------------------*/
.o-grid {
    lost-center: 100%;

    /* Narrow */
    &--narrow {
        lost-center: 800px $base-spacing-unit*2;
    }


    /* widths */
    &--990 {
        max-width: 990px;
    }

    &__off1\/6 {
        lost-column: 1/1;
        @include bp(lap) {
            lost-offset: 1/6;
        }
    }

    &__1\/1 {
        lost-column: 1/1;
    }

    &__1\/2 {
        lost-column: 1/1;
        @include bp(lap) {
            lost-column: 1/2
        }
    }

    &__1\/2-tab {
        lost-column: 1/1;
        @include bp(tab) {
            lost-column: 1/2 2 120px !important;
        }
    }

    &__1\/4 {
        lost-column: 1/1;
        @include bp(lap) {
            lost-column: 1/3;
        }
        @include bp(desk) {
            lost-column: 1/4;
        }
    }

    &__3\/4 {
        lost-column: 1/1;
        @include bp(lap) {
            lost-column: 2/3;
        }
        @include bp(desk) {
            lost-column: 3/4;
        }
    }

    &__1\/3 {
        lost-column: 1/1;
        @include bp(lap) {
            lost-column: 1/3;
        }
    }

    &__1\/6 {
         lost-column: 1/1;
         @include bp(lap) {
             lost-column: 1/6;
         }
     }

    &__5\/6 {
        lost-column: 1/1;
        @include bp(lap) {
            lost-column: 5/6;
        }
    }

    &__2\/3 {
        lost-column: 1/1;
        @include bp(lap) {
            lost-column: 2/3;
        }
    }

    &__7\/12 {
        lost-column: 1/1;
        @include bp(lap) {
            lost-column: 7/12 12 6px;
        }
    }

    &__5\/12 {
        lost-column: 1/1;
        @include bp(lap) {
            lost-column: 5/12 12 6px;
        }
    }

    &__1\/6 {
        lost-column: 1/1;
        @include bp(lap) {
            lost-column: 1/6;
        }
    }

    &__3\/10 {
        lost-column: 1/1;
        @include bp(lap) {
            lost-column: 3/10;
        }
    }
    &__7\/10 {
        lost-column: 1/1;
        @include bp(lap) {
            lost-column: 7/10;
        }
    }
    &__off1\/3 {
        lost-column: 1/1;
        @include bp(tab) {
            lost-column: 2/4;
            lost-offset: 1/4;
        }
    }
}

/*------------------------------------*\
    #o-flexswap - change column places
\*------------------------------------*/
.o-flexswap {
    display: flex;
    flex-direction: column;
    @include bp(tab) {
        flex-direction: row;
    }

    &__left {
        order: 2;
        @include bp(tab) {
            order: 1;
        }
    }

    &__right {
        order: 1;
        @include bp(tab) {
            order: 2;
        }
    }
}


/*------------------------------------*\
    #o-flexleftright
\*------------------------------------*/
.o-flexleftright {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &__left,
    &__right {
        display: flex;
        align-items: center;
    }
}



/*------------------------------------------------------------*\
    #Layout constructions
\*------------------------------------------------------------*/
/*------------------------------------*\
    #FLEXBOX center v and vh
    - needs height
\*------------------------------------*/
.o-centerv {
    display: flex;
    justify-content: center;
}
.o-centerh {
    display: flex;
    align-items: center;
}
.o-centervh {
    display: flex;
    align-items: center;
    justify-content: center;
}

/*------------------------------------*\
    #Cover the whole page
\*------------------------------------*/
.o-cover-layout {
    display: flex;
    flex: 1 0 auto;
    min-height: 100vh;
    flex-direction: column;
}


/*------------------------------------*\
    #Login/register pages layout
\*------------------------------------*/
.o-loginlayout {
    background-color: white;
    animation-duration: .4s;
    animation-fill-mode: both;
    animation-name: scaleInUp;

    @include bp(lap) {
        display: flex;
    }

    &--v2 {
        display: flex;
        flex-direction: column-reverse;

        @include bp(lap) {
            flex-direction: row;
        }
    }

    &__sidebar {
        padding: 20px;

        svg path {
            fill: #00A0FC;
        }

        @include bp(lap) {
            flex: 0 0 480px;
            background-color: #00A0FC;
            padding: 32px;
            min-height: 100vh;

            svg path {
                fill: #fff;
            }
        }

        &--login {
            @include bp(lap) {
                background: #fff url("https://vervoe-live.s3.amazonaws.com/assets/0001/03/bg--login.jpg") no-repeat 50% 50%;
                background-size: cover;
            }
        }
        &--register {
            @include bp(lap) {
                background: #fff url("https://vervoe-live.s3.amazonaws.com/assets/0001/03/bg--register.jpg") no-repeat 50% 50%;
                background-size: cover;
            }
        }

        &--flex {
            background: linear-gradient(44.51deg, #00A0FC 0%, #00DDFC 100%);
            padding: 115px 32px;

            @include bp(lap) {
                padding: 32px;
                background: linear-gradient(44.51deg, #00A0FC 0%, #00DDFC 100%);
            }
        }

        &--text {
            width: 240px;
            color: #fff;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            margin-bottom: 90px;

            &:last-child {
                margin-bottom: 0;
            }

            @include bp(lap) {
                margin-bottom: 0;
            }
        }
    }

    &__sidebar--textholder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        @include bp(lap) {
            height: 100vh;
        }
    }

    &__logo {
        display: none;
        @include bp(lap) {
            display: block;
        }
    }



    &__main {
        background-color: white;
        padding: 20px;
        display: flex;
        flex: 1 0 auto;
        min-height: 100vh;

        @include bp(lap) {
            justify-content: center;
            align-items: center;
            padding: 48px;
        }
    }

    &__content {
        width: 100%;

        @include bp(lap) {
            max-width: 380px;
        }

        &-v2 {
            padding: 45px 0;
            @include bp(lap) {
                padding: 0;
                max-width: 450px;
            }
        }

    }
}

@keyframes circle
{
    0%
    {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);}
    100%
    {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes circle-counterclock-wise
 {
     0%
     {
         -webkit-transform: rotate(0);
         -ms-transform: rotate(0);
         -o-transform: rotate(0);
         transform: rotate(0);}
     100%
     {
         -webkit-transform: rotate(-360deg);
         -ms-transform: rotate(-360deg);
         -o-transform: rotate(-360deg);
         transform: rotate(-360deg);
     }
 }

@keyframes scaleInUp {
    from {
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
@media
only screen and (-webkit-min-device-pixel-ratio: 2) and(min-width: 992px) and ( min-height: 1000px),
only screen and (min--moz-device-pixel-ratio: 2) and(min-width: 992px) and ( min-height: 1000px),
only screen and (-o-min-device-pixel-ratio: 2/1) and(min-width: 992px) and ( min-height: 1000px),
only screen and (min-device-pixel-ratio: 2) and(min-width: 992px) and ( min-height: 1000px),
only screen and (min-resolution: 192dpi) and(min-width: 992px) and ( min-height: 1000px),
only screen and (min-resolution: 2dppx) and(min-width: 992px) and ( min-height: 1000px) {
    .o-loginlayout__sidebar--login {
        background: #fff url("https://vervoe-live.s3.amazonaws.com/assets/0001/03/bg--login@3x.jpg") no-repeat 50% 50%;
        background-size: cover;
    }
}
@media
only screen and (-webkit-min-device-pixel-ratio: 2) and(min-width: 992px),
only screen and (min--moz-device-pixel-ratio: 2) and(min-width: 992px),
only screen and (-o-min-device-pixel-ratio: 2/1) and(min-width: 992px),
only screen and (min-device-pixel-ratio: 2) and(min-width: 992px),
only screen and (min-resolution: 192dpi) and(min-width: 992px),
only screen and (min-resolution: 2dppx) and(min-width: 992px) {
    .o-loginlayout__sidebar--login {
        background: #fff url("https://vervoe-live.s3.amazonaws.com/assets/0001/03/bg--login@2x.jpg") no-repeat 50% 50%;
        background-size: cover;
    }
}
