/*------------------------------------*\
    #Tables
\*------------------------------------*/

$enable-tables-zebra      : true !default;
$enable-tables-horizontal : true !default;
$table-border-color       : #CFCFCF;

/**
 *  Tables
 */
.o-table {
    width: 100%;

    tr, th, td, caption {
        padding: $base-spacing-unit*2.4;
        border: 1px solid $table-border-color;
        font-size: 13px;
    }

    td {vertical-align: top; color: $grey-dark;}

    caption {
        font-weight: normal;
        font-size: 16px;
        padding: 0 0 $base-spacing-unit*2.4;
        border: 0;
        text-align: left;
    }

    th {
        font-weight: normal;
        text-align: left;
    }
}

//  ------------------- enable/disable in config ---------------------------

@if ($enable-tables-zebra == true) {
    /**
     *  "Zebra" tables
     */
    .o-table--zebra tbody tr:nth-child(odd) {
        background: #f5f5f5;
    }
}


@if ($enable-tables-horizontal == true) {
    /**
     *  Tables with only horizontal borders
     */
    .o-table--horizontal {
        tr, th, td, caption {
            padding: $base-spacing-unit*2.4 0;
            border-width: 1px 0;
        }
        caption {
            padding: 0 0 $base-spacing-unit*2.4;
            border: 0;
        }
    }
}


@if ($enable-tables-zebra == true) and ($enable-tables-horizontal == true) {
    /**
     *  Tables with only horizontal borders and "zebra" colors
     */
    .o-table--horizontal.o-table--zebra {
        tr, th, td, caption {
            padding: $base-spacing-unit*2.4 0;
            border-width: 1px 0;
        }
        caption {
            padding: 0 0 $base-spacing-unit*2.4;
            border: 0;
        }
    }
}