.o-mediablock {
    @include mediablock(left, 12px, '.o-mediablock__img', '.o-mediablock__body');

    &__body {
        > :first-child {margin-top: 0 !important;}
        > :last-child  {margin-bottom: 0 !important;}
    }
}

.o-mediablock--tab {
    @include bp(tab) {
        @include mediablock(left, 30px, '.o-mediablock__img', '.o-mediablock__body');
    }
}

/*------------------------------------*\
    #Flag block
\*------------------------------------*/
.o-flagblock {
    display: flex;
    align-items: center;

    &__img {
        margin-right: 14px;
        flex: 0 0 auto;
    }
    &__body {
        flex: 1 0 auto;
    }
}

// google api location container
.pac-container {
    z-index: 10000;
}

@media (max-width : 767px) {
    .cookie_mobile {
        left: 0 !important;
    }
    .cookie_mobile span {
        display: none;
    }
}
