/*------------------------------------*\
    #marketcards
\*------------------------------------*/

.cm-s-material {
    &.CodeMirror{
        background: #282b34 !important;
    }
}

.CodeMirror-gutter{
    &.CodeMirror-linenumbers{
        background: #282b34 !important;
    }
}

.cm-s-material{
    height: 100% !important;
    & .CodeMirror-linenumber{
        color: #ffffff !important;
        opacity: 0.3 !important;
    }
    & .cm-comment {
        color: #ffffff !important;
        opacity: 0.3 !important;
    }
}
.CodeMirror {
    &__body, &__footer, &__header {
        height: 100% !important;
        & > .cm-s-material {
            height: 100% !important;
        }
    }
    &__preView {
        height: 300px !important;
        & .CodeMirror-vscrollbar {
            display: none !important;
        }
    }
}



.react-codemirror2 {
    font-size: 16px !important;
    &.preView {
        font-size: 12px !important;
    }
}

.c-marketcards {
    font-family: $font-stack---open-sans;
    margin-bottom: 40px;
    margin-top: 20px;

    /* card */
    &__item {
        transition: .2s;
        background-color: #fff;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        padding: 14px 16px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.13), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        position: relative;
        overflow: hidden;

        &--opened {
            .c-marketcards__desc {
                opacity: 1;
                transition: opacity 0.3s;
            }
            .c-marketcards__buttons {
                opacity: 1;
                @media screen and (max-width: 767px) {
                    height: 168px;
                }
            }
            .c-marketcards__read-more {
                display: none;
            }
        }

        &:hover {
            &:not(.c-marketcards__item--opened) .c-marketcards__desc {
                opacity: 0;
                transition: opacity 0.3s;
            }
            .c-marketcards__buttons {
                opacity: 1;
            }
        }

        /* if it's featured */
        &.is-featured {
            &:after {
                content: 'featured';
                position: absolute;
                top: 16px;
                right: -22px;
                width: 90px;
                transform: rotate(405deg);
                color: #FFFFFF;
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 700;
                line-height: 16px;
                background: #436cff;
                text-align: center;
            }
        }

        /* if it's private and published */
        &.is-public:not(.is-featured) {
            &:after {
                content: 'public';
                position: absolute;
                top: 16px;
                right: -20px;
                width: 90px;
                transform: rotate(405deg);
                color: #FFFFFF;
                font-size: 10px;
                font-weight: 600;
                line-height: 14px;
                background: $blue;
                text-align: center;
            }
        }
    }

    &__info {
        text-transform: uppercase;
        font-size: 10px;
        color: $black-54;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 3px;
    }


    &__header {
        position: relative;
        font-size: $base-font-size;
        font-weight: normal;
        // background-color: #EB533C;
        color: $grey-60;
        margin: -16px -16px 0;
        padding: 1.5rem 1rem;
        text-align: center;
        height: 50px;

        /* color variations */
        // &--hardskills      {background-color: #EB533C;}
        // &--practicaltest   {background-color: #E2C652;}
        // &--personalitytest {background-color: #4CD3A3;}
    }

    &__heading {
        position: relative;
        word-wrap: break-word;
        margin-bottom: 7px;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #1a1b1e;
    }

    &__desc {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.2px;
        color: #1a1b1e;
        min-height: 42px;
        display: flex;
        align-items: center;
        justify-content: left;
    }

    &__meta {
        margin-bottom: 9px;
        width: 369px;
        height: 18px;
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 0.1px;
        color: #676a78;

        i {
            font-size: 20px;
            margin-right: 8px;
            vertical-align: middle;
        }
    }

    &__divider {
        height: 60px;
        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    &__buttons {
        opacity: 0;
        transition: opacity 0.3s, height 0.3s;
        position: absolute;
        bottom: 10px;
        left: 16px;
        width: calc(100% - 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .button-wrapper {
            @media screen and (max-width: 767px) {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }

        .action-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @media screen and (max-width: 767px) {
            position: static;
            width: 100%;
            height: 0;
            flex-direction: column;
            overflow: hidden;
        }
    }

    &__icon {
        margin-right: 9px;
    }

    &__icon-menu {
        @media screen and (max-width: 767px) {
            position: absolute;
            right: 0px;
            top: 10px;
        }
    }
}



/*------------------------------------*\
    #Star rating labels
\*------------------------------------*/
label.dv-star-rating-star {
    padding: 0 !important;
}

//marketplace loader
.spinner {
    margin: 40px auto;
    width: 70px;
    text-align: center;
}

.spinner > div {
    width: 18px;
    height: 18px;
    background-color: $black-20;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}
