.item {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  padding-left: 5px !important;
  padding-right: 5px !important;

  &:not(:last-child) {
    border-bottom: solid 1px #000000;
  }

  & svg {
    min-width: 24px;
  }

}

.iconWrapper {
  min-width: 42px;
}

.name {
  color: #ffffff;
  width: 200px;
  overflow: hidden;
  text-overflow: clip;
  text-transform: uppercase;
}

.scoreWrapper {
  padding: 0 35px;
}

.passed {
  color: #2fd1b8;
}

.failed {
  color: #ff5e5e;
}
