.inviteFormRowWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  min-height: 70px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    margin-top: 25px;
  }

  .fieldWrapper {
    flex-grow: 1;
    margin-bottom: 10px;
    margin-top: 5px;

    &:first-child {
      margin-right: 24px;
    }

    &:nth-child(3) {
      margin-left: 24px;
    }

    & input {
      height: 1.25em;
    }
  }
}