@font-face {
  font-family: 'icomoon-vervoe';
  src:  url('../fonts/icomoon-new/icomoon-vervoe.eot');
  src:  url('../fonts/icomoon-new/icomoon-vervoe.eot') format('embedded-opentype'),
  url('../fonts/icomoon-new/icomoon-vervoe.svg') format('svg'),
  url('../fonts/icomoon-new/icomoon-vervoe.ttf') format('truetype'),
  url('../fonts/icomoon-new/icomoon-vervoe.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proximanova Extrabld';
  src:  url('../fonts/proximanova-extrabld/proximanova-extrabld.eot');
  src:  url('../fonts/proximanova-extrabld/proximanova-extrabld.eot') format('embedded-opentype'),
  url('../fonts/proximanova-extrabld/proximanova-extrabld.ttf') format('truetype'),
  url('../fonts/proximanova-extrabld/proximanova-extrabld.woff') format('woff'),
  url('../fonts/proximanova-extrabld/proximanova-extrabld.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proximanova';
  src:  url('../fonts/proximanova/proximanova.eot');
  src:  url('../fonts/proximanova/proximanova.eot') format('embedded-opentype'),
  url('../fonts/proximanova/proximanova.ttf') format('truetype'),
  url('../fonts/proximanova/proximanova.woff') format('woff'),
  url('../fonts/proximanova/proximanova.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Gellix';
  src:  url('../fonts/gellix/gellix.eot');
  src:  url('../fonts/gellix/gellix.eot') format('embedded-opentype'),
  url('../fonts/gellix/gellix.ttf') format('truetype'),
  url('../fonts/gellix/gellix.woff') format('woff'),
  url('../fonts/gellix/gellix.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Gellix-Bold';
  src:  url('../fonts/gellix-bold/Gellix-Bold.eot');
  src:  url('../fonts/gellix-bold/Gellix-Bold.eot') format('embedded-opentype'),
  url('../fonts/gellix-bold/Gellix-Bold.ttf') format('truetype'),
  url('../fonts/gellix-bold/Gellix-Bold.woff') format('woff'),
  url('../fonts/gellix-bold/Gellix-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
