//  ----------------------------------------
//  #Lists
//  ----------------------------------------

.candidates-not-found,
.candidates-not-exist {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 0;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(242, 242, 242, 0.5);

    &__emoji {
        display: inline-block;
        padding-bottom: 10px;
        font-size: 40px;
        line-height: normal;
        text-align: center;
        color: #000000;
    }

    &__title {
        font-size: 18px;
        font-weight: bold;
        line-height: normal;
        text-align: center;
        color: rgba(0, 0, 0, 0.8);
    }

    &__text {
        max-width: 255px;
        font-size: 12px;
        line-height: 1.5;
        text-align: center;
        color: #000000;
    }
}

.candidates-not-found,
.candidates-not-exist {
    overflow: hidden;
    min-height: 50vh;
}

.candidates-not-exist {
    &__text {
        max-width: 370px;
        font-size: 13px;
        line-height: 1.38;
        opacity: 0.5;
    }
}

.list-item > div {
    background-color: #ffffff;
    cursor: pointer;
}

.list-item:hover > div {
    background-color: rgba(0, 0, 0, 0.02);
}


/*------------------------------------*\
    #activitylist
\*------------------------------------*/
.c-activity {
    margin: 0;

    &__item {
        display: flex;
        margin: 0;
        padding: 0 0 1.2rem;

        &:first-child {
            padding-top: 1.2rem;
            .c-activity__item__avatar,
            .c-activity__item__info {padding-top: 0;}
        }
        &:last-child {
            padding-bottom: 1.2rem;
        }

        & + & {
            .c-activity__item__info,
            .c-activity__item__num {
                border-top: 1px solid #CFCFCF;
            }
        }

        p {
            min-width: 0;
            margin: 0;
            line-height: 1.2;
            word-wrap: break-word;
        }

        a {
            color: $blue;
            &:hover {color: #000;}
        }

        &__avatar {
            display: flex;
            align-items: center;
            flex: 0 0 auto;
            padding-top: 1.2rem;
            overflow: hidden;
            margin-right: 1.4rem;
        }

        &__info {
            padding-top: 1.2rem;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
        }

        &__num {
            padding-top: 1.2rem;
            padding-right: 1.4rem;
            display: flex;
            align-items: center;
            flex: 1;
            text-align: center;

            span {
                display: inline-block;
                color: #fff;
                background: $blue;
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                border-radius: 50%;
                margin-left: 1rem;
            }
        }

        &:link:hover {background: #ddd;}

    }

    /* if comments */
    &.c-activity--comments {
        padding-left: 0;
        margin-top: 24px;

        &:first-child {padding: 1.2rem 0 0;}
        &:last-child {padding: 0;}

        p {
            margin: 0 0 .5em;
            font-size: 14px;
        }
        .c-activity__item {
            align-items: flex-start;
        }
    }
}

/*------------------------------------*\
    #1) 2) 3) lists
\*------------------------------------*/
.c-orderedlist {
    counter-reset: list;
    padding: 0;
    margin: 0;

    li {
        list-style: none;
        font-size: 14px;
        line-height: 16px;
        color: $black-87;
        position: relative;
        margin-bottom: 30px;

        @include bp(tab) {
            display: flex;
            align-items: center;
            margin-bottom: 0;
        }

        &:before {
            display: block;
            margin-bottom: 10px;
            margin-top: 7px;
            width: 20px;
            content: counter(list) ")";
            counter-increment: list;
            color: $black-87;

            @include bp(tab) {
                margin-bottom: 0;
            }
        }
    }
}
