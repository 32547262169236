//  ------------------------------------
//  #Height sizes
//  ------------------------------------
// example: u-hgt--10

$height-sizes: ( 10, 20, 30, 46, 60, 70, 80, 100 );
@each $name in $height-sizes {
    %u-hgt--#{$name},
    .u-hgt--#{$name} {height: #{$name}px !important;}
}


// min-height

$height-sizes: ( 70, 120, 185 );
@each $name in $height-sizes {
    %u-hgt-min--#{$name},
    .u-hgt-min--#{$name} {min-height: #{$name}px !important;}
}

.u-hgt-min--auto {min-height: auto !important;}
.u-hgtm--auto {max-height: none !important;}
.u-hgt--100p {height: 100% !important;}

/* full height */
.u-fullheight {
    height: 100vh;

    &--70 {
        height: 70vh;
    }
}
