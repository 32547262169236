//  ------------------------------------
//  #Text utilities
//  ------------------------------------
%u-txt--center, .u-txt--center      {text-align: center !important;}
%u-txt--left, .u-txt--left          {text-align: left !important;}
%u-txt--right, .u-txt--right        {text-align: right !important;}

//  text transformations
%u-txt--up, .u-txt--up              {text-transform: uppercase !important;}
%u-txt--low, .u-txt--low            {text-transform: lowercase !important;}
%u-txt--cap, .u-txt--cap            {text-transform: capitalize !important;}
%u-txt--notr, .u-txt--notr          {text-transform: none !important;}
%u-txt--ita, .u-txt--ita            {font-style: italic !important;}

//  text line heights
%u-txt--lh1, .u-txt--lh1            {line-height: 1 !important;}
%u-txt--lh12, .u-txt--lh12          {line-height: 1.2 !important;}
%u-txt--lh14, .u-txt--lh14          {line-height: 1.4 !important;}
%u-txt--lh16, .u-txt--lh16          {line-height: 1.6 !important;}
%u-txt--lh17, .u-txt--lh17          {line-height: 1.7 !important;}
%u-txt--lh18, .u-txt--lh18          {line-height: 1.8 !important;}

//  text sizes
//  dependencies: $text-sizes, maps in 0_settings/_vars.scss
@each $name, $size in $text-sizes {
    %u-txt--#{$name},
    .u-txt--#{$name}                {font-size: $size !important;}
}

//  text font weights
%u-txt--light, .u-txt--light        {font-weight: 300 !important;}
%u-txt--normal, .u-txt--normal      {font-weight: 400 !important;}
%u-txt--thick, .u-txt--thick        {font-weight: 500 !important;}
%u-txt--semibold, .u-txt--semibold  {font-weight: 600 !important;}
%u-txt--bold, .u-txt--bold          {font-weight: 700 !important;}
%u-txt--extra-bold, .u-txt--extra-bold          {font-weight: 800 !important;}


//  text decorations
%u-txt--underline, .u-txt--underline {text-decoration: underline !important;}

//  truncate text
%u-txt--truncate, .u-txt--truncate {
    max-width     : 100%;
    overflow      : hidden !important;
    text-overflow : ellipsis !important;
    white-space   : nowrap !important;
    word-wrap     : normal !important;
}

//text-wrapping
%u-txt--nowrap, .u-txt--nowrap {white-space: nowrap;}
%u-txt--prewrap, .u-txt--prewrap {white-space: pre-wrap;}
%u-txt--break, .u-txt--break    {word-wrap: break-word;}

