/*------------------------------------*\
    #Buttons
\*------------------------------------*/

$btn-border-radius : $base-border-radius;
$btn-border-width  : 1px;
$btn-padding-h     : 12px;
$btn-padding-v     : 12px;

.c-btn {
    display       : inline-block;
    padding       : $btn-padding-v $btn-padding-h;
    width         : auto;
    border        : 0;
    border-radius : $btn-border-radius;
    background    : $blue;
    color         : #fff;
    font-size     : 12px;
    font-weight   : 400;
    line-height   : normal;
    transition    : all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    text-align    : center;
    cursor        : pointer;
    border        : $btn-border-width solid $brand;

    &:hover {
        border           : 0;
        background-color : darken($blue, 20%);
        color            : #fff;
        border           : $btn-border-width solid darken($blue, 20%);
    }

    &:disabled, &--disabled {
        cursor: default;
        pointer-events: none;
        -webkit-filter: grayscale(100%);
    }

    /* blank button */
    &--blank {
        background: transparent none;
        border-color: transparent;

        &:hover {
            background: transparent none;
            border-color: transparent;
        }
        &:active, &:focus {color: inherit;}
    }

    &:focus {
        outline: none;
    }

    /* block button */
    &__block {
        display    : block;
        text-align : center;
        width      : 100%;
    }

    /**
     *  Ghost buttons
     */
    &__ghost-blue {
        border         : $btn-border-width solid transparent;
        background     : none;
        color          : $brand;
        text-transform : uppercase;
        font-weight    : 500;
        font-size      : 14px;
        line-height    : 14px;
        padding        : 10px 12px;

        &:hover {
            border       : $btn-border-width solid $brand;
            background: none;
            color: $brand;

        }
    }

    &__ghost-blue-fill {
        border         : $btn-border-width solid $brand;
        background     : $brand;
        color          : $white;
        text-transform : uppercase;
        font-weight    : 500;
        font-size      : 14px;
        line-height    : 14px;
        padding        : 10px 12px;

        &:hover {
            border: $btn-border-width solid $brand;
            background: none;
            color: $brand;

        }
    }

    &--ghost {
        border     : $btn-border-width solid $blue;
        background : transparent;
        color      : $blue;

        &:hover {
            border       : $btn-border-width solid $blue;
            background   : $blue;
            color        : #fff;
        }
    }

    &__ghost-market {
        border         : $btn-border-width solid $green;
        background     : #fff;
        color          : $green;
        padding        : 3px 22px;
        font-weight    : 500;
        font-size      : 14px;
        line-height    : 26px;
        letter-spacing : 1px;

        &:hover {
            border       : $btn-border-width solid $green;
            background   : $green;
            color        : #fff;
        }
    }

    &--ghost-red {
        border         : $btn-border-width solid #ff5e5e;
        background     : transparent;
        color          : #ff5e5e;
        padding        : 10px 12px;
        text-transform : uppercase;
        font-weight    : 500;
        font-size      : 14px;
        line-height    : 14px;

        &:hover {
            border       : $btn-border-width solid #ff5e5e;
            background   : #ff5e5e;
            color        : #fff;
        }
    }

    &--ghost-blue {
        border         : $btn-border-width solid #1247F6;
        background     : transparent;
        color          : #1247F6;
        padding        : 10px 12px;
        text-transform : uppercase;
        font-weight    : 500;
        font-size      : 14px;
        line-height    : 14px;
        text-align     : center;

        &:hover {
            border       : $btn-border-width solid #00a0fc;
            background   : rgb(12, 49, 172);
            color        : #fff;
        }
    }

    /* rounded */
    &__rounded{
        border-radius :  ($btn-border-radius*7);
        letter-spacing: 1px;
        padding: 14px  26px;
        font-size: 18px !important;
    }

    /* white transparent (in header) */
    &__white-muted {
        text-transform: none;
        background: #fff;
        font-size: 13px;
        color: rgba(0,0,0,0.20);
        padding: 8px 10px;
        border: 0;

        &:hover, &:focus, &.active {
            border: 0;
            background: #fff;
            color:  rgba(0,0,0,0.50);
        }
    }
    &--muted {
        text-transform: none;
        background: rgba(#fff, .2);
        font-size: 13px;
        color: #fff;

        i {
            font-size: 20px;
            vertical-align: -0.4ex;
        }

        &:hover, &:focus, &.active {
            background: rgba(#fff, 1);
            color: #333;
        }
    }

    /* dark blue (sub-header on job opportunities ) */
    &__dark {
        background: $brand-dark;
        padding: 0 $btn-padding-h;
        height: 36px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        text-align: center;
        &:hover, &:focus, &.active {
            background: rgba(#fff, .3);
            color: #fff;
        }
    }

    &__default {
        background: $brand;
        border: 1px solid $brand;
        font-size: 16px;
        line-height: 18px;
        padding: 14px 12px;
        border-radius: 0;
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        &:hover, &:focus, &.active {
            color: $brand;
            border: 1px solid $brand;
        }
    }

    &__default-ghost {
        background: #fff;
        font-size: 16px;
        line-height: 18px;
        padding: 14px 12px;
        border-radius: 0;
        color: $brand;
        border: 1px solid $brand;
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        &:hover, &:focus, &.active {
            color: #fff;
            background: $brand;
            border: 1px solid $brand;
        }
    }

    &__market {
        border         : $btn-border-width solid $green;
        background     : $green;
        color          : #fff;
        padding        : 3px 22px;
        font-weight    : 500;
        font-size      : 14px;
        line-height    : 26px;
        letter-spacing : 1px;

        &:hover {
            border       : $btn-border-width solid $green;
            background   : #fff;
            color        : $green;
        }
    }

    /* wide */
    &--wide {
        padding-left: ($btn-padding-h*3);
        padding-right: ($btn-padding-h*3);
    }
    /* small */
    &__sm {
        padding-top: ($btn-padding-h/2);
        padding-bottom: ($btn-padding-h/2);
    }
    /* large */
    &__lg {
        padding-top: 18px;
        padding-bottom: 18px;
    }



    /* shape variants */
    &--pill {
        border-radius: 50em;
    }
}

.c-button-link {
    background: none;
    padding: 0;
    width: auto;
    outline: none;
    border: none;
    text-align: left;
    &.active {
        color: $brand;
    }
}
