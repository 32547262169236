.c-header-main {
    background: #fff;
    position: relative;
    border-bottom: 1px solid #F2F2F4;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.13);
}

.c-header {
    width: 100%;

    @include bp(tab) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    /* logo */
    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--right {
        display: flex;
        align-items: center;

        li {
            margin-right: 20px;

            &:last-child {margin-right: 0;}

            a {
                color: #fff;

                i {
                    font-size: 16px;
                    opacity: 0.5;
                }

                &:hover {
                    i {
                        opacity: 1;
                    }
                }

                &.is-muted {
                    opacity: 0.5;
                    &:hover {opacity: 1;}
                }
            }


        }

        .c-img-placeholder {
            font-size: 16px;
            font-weight: 400;
        }
    }

    //  ------------------------------------
    //  #Dropdown visible on mobile
    //  ------------------------------------
    &--dropdown {
        position: absolute;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 10;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.13);

        @include bp(tab) {
            display: none !important;
        }

        li {
            // border-top: solid 1px #d6d6d6;
            border-top: solid 1px #f2f2f4;

            a, button {
                display: block;
                line-height: 28px;
                color: $black-87;
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
                padding: 16px 30px 10px;
                @include bp(tab) {
                    padding: 16px 7px 10px;
                    height: 60px;
                }
                &:active, &.active, &:focus {
                    color: $black-87;
                    border-bottom: 0;
                }
                &:hover {
                    color: $black-87;
                    border-bottom: 0;
                }
            }
        }
    }

    //  -----------------------------------------------
    //  #Jobseeker and author headers (old ones )
    //  -----------------------------------------------
    &--default {
        padding: 17px 0 0;

        @include bp(tab) {
            padding: 0;
        }

        li:not(.dropdown-menu--item) > a {
            font-size: 16px;
            font-weight: 600;
            color: #fff;
            &:hover {
                color: $blue-light;
            }
        }

        //  ------------------------------------
        //  #Right side navbar links
        //  ------------------------------------
        .c-header--right {
            height: 70px;

            @include bp(tab) {
                height: 60px;
            }

            li {
                position: relative;
                margin-right: 14px;
                &:last-child {margin: 0;}

                @media only screen and (max-width: 768px) {
                    text-align: center;
                    width: 100%;
                }

                i {
                    font-size: 40px;
                    vertical-align: middle;
                    position: relative;
                    @include bp(tab) {
                        font-size: 28px;
                    }
                }
            }

            @media only screen and (max-width: 768px) {
                border-top: 1px solid #fff;
            }
        }
    }

    //  ----------------------------------------------------------------------------------------------
    //  #New employer header (change this class when headers for author and jobseeker are changed too)
    //  ----------------------------------------------------------------------------------------------
    &--employer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        height: 50px;

        @media screen and (max-width: 767px) {
            padding: 0 10px;
        }

        .c-header--right {
            height: auto;
            justify-content: flex-end;
            @media only screen and (min-width: 1300px) {
                width: 45%;
            }
            .c-btn {
                @include bp(tab) {
                    margin-right: 18px;
                }
            }
        }
    }

    //  ------------------------------------
    //  #Left navbar links for employer
    //  ------------------------------------
    &--left {
        @media only screen and (min-width: 1300px) {
            width: 45%;
        }
        li {
            float: left;
            font-weight: 500;

            &:first-child {
                padding: 0;
            }

            &:last-child a {
                margin-right: 0;
            }

            a {
                font-size: 12px;
                text-transform: uppercase;
                color: $black-54;
                margin-right: 2vw;

                &.active,
                &:hover {
                    color: $black-87;
                }
            }
        }
    }
}
