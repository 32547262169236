//  ------------------------------------
//  #Colors
//  ------------------------------------

//  Brand color
$brand             : #1247F6;
$brand-dark        : rgb(12, 49, 172);

//shades of blue
$blue              : #1247F6;
$blue-dark         : rgb(12, 49, 172);
$blue-light        : #daf1fe;

//shades of grey
$grey6             : #666666;
$grey9             : #999999;
$grey-e5           : #e5e5e5;
$grey-60           : #606062;
$grey-f5           : #f5f5f5;
$lightest-grey     : #f2f5f8;
$grey-light        : #979797;
$grey-dark         : #1D2531;

//shades of black
$black-87         : rgba(#000, .87);
$black-63         : rgba(#000, .63);
$black-54         : rgba(#000, .54);
$black-50         : rgba(#000, .50);
$black-28         : rgba(#000, .28);
$black-20         : rgba(#000, .20);

//other colours
$red               : #de1a00;
$orange            : #ff9b00;
$deeporange        : #FC5D00;
$white             : #ffffff;
$green             : #2FD1B8;
$coral             : #fd5339;
$jade              : #65CBCB;
$yellow            : #FFBC3C;

$font-color        : #292A2D;
$border-color      : #dddddd;

//  ------------------------------------
//  #UI colors
//  ------------------------------------
$ui-colors: (
    "brand"           : #00a0fc,
    "brand-new"       : #1247F6,
    "brand-dark"      : #1d2530,
    "lightest-grey"   : #f2f5f8,
    "grey-e5"         : #e5e5e5,
    "lightest-gray-l" : #f8fafb,
    "grey-dark"       : #1D2531,
    "grey6"           : #666666,
    "grey-60"         : #606062,
    "grey-df"         : #dfdfe0,
    "grey9"           : #999999,
    "white"           : #ffffff,
    "blue"            : #00a0fc,
    "blue-dark"       : #0a517b,
    "blue-verydark"   : #00283F,
    "green"           : #2FD1B8,
    "orange"          : #ff9b00,
    "deeporange"     : #FC5D00,
    "red"             : #de1a00,
    "jade"            : #65CBCB,
    "black-87"         : rgba(#000, .87),
    "black-63"         : rgba(#000, .63),
    "black-54"         : rgba(#000, .54),
    "black-28"         : rgba(#000, .28),
    "black-20"         : rgba(#000, .20),
    "grey-f5"          : #f5f5f5
);
