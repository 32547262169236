/*------------------------------------*\
    #Create box
\*------------------------------------*/
.c-createbox {
    display: block;
    width: 100%;
    padding: 78px 45px;
    background: #fff;
    color: rgba(0,0,0,0.50);
    border-radius: $base-border-radius;
    text-align: center;

    > :first-child  {margin-top: 0 !important;}
    > :last-child   {margin-bottom: 0 !important;}



    /* done with creation? */
    &.is-created {
        border: 2px solid #fff;
    }


    /* Size variations */
    &--sm {
        padding: 30px 45px;
    }

    &--xsm {
        padding: 24px 14px;
    }

    &--board {
        max-width: 270px;
    }

}

/* if it's link */
button.c-createbox {
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20), 0 1px 5px 0 rgba(0,0,0,0.13);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    &:hover,
    &:focus,
    &:active {
        color: rgba(0,0,0,0.8);
        box-shadow: 0 8px 17px 0 rgba(0,0,0,0.20), 0 6px 20px 0 rgba(0,0,0,0.19);
    }
}


/*------------------------------------*\
    #Icons within text
\*------------------------------------*/
.c-icontxt {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: -.25ex;
    fill: #1247F6;
}

//  ------------------------------------
//  #Element with bottom border
//  ------------------------------------
.c-underline {
    &:last-of-type {
        hr {display: none;}
    }
}

/*------------------------------------
    #Upload photo
------------------------------------*/
.o-mediablock__img > .c-upload-photo > div {display: block;}
.c-upload-photo {
    position: relative;
    text-align: center;

    & > div {display: inline-block;}

    img {border-radius: 50%;}

    button {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
        margin: 0;
        padding: 0;
        transition: .1s;
        opacity: 0;
        color: #1247F6;
        border: 0;
        line-height: 1.2;
        font-weight: 600;
        background: rgba(255, 255, 255, .8);
        border-radius: 50%;
    }

    &_rectangle {
        display: inline-block;
        img {border-radius: 0;}
        .c-img-placeholder {
            width: 100px;
            height: 100px;
        }
    }

    &:hover button {opacity: 1;}
}

.c-img-dropzone {
    padding: 25px 15px;
    border: 4px dashed #E5E5E5;
    border-radius: 10px;
    color: #888888;
    text-align: center;

    &--active {
        border-color: $blue;
    }

    &__icon {
        font-size: 44px;
        display: block;
        margin: 0 auto 20px;
    }

    &__title {
        font-size: 24px;
        font-weight: bold;
        line-height: 28px;
    }

    &__txt {
        font-size: 13.8px;
        line-height: 16px;
    }
}



/*------------------------------------*\
    #Download link with icon
\*------------------------------------*/
.c-download-file {
    color: $grey-dark;
    padding-left: 26px;
    line-height: 20px;
    position: relative;
    margin-top: 5px;
    display: inline-block;

    &:hover {
        color: $blue;
    }

    &:before {
        position: absolute;
        left: 0;
        top: -1px;
        content: '\e916';
        font-size: 20px;
        color: $blue;
        font-family: 'icomoon-vervoe';
    }
}

/*------------------------------------*\
    #Hamburger
\*------------------------------------*/
.hamburger {
    position: relative;
    bottom: -4px;
}


/*------------------------------------
    #Material ui menu
------------------------------------*/
.c-muimenu {
    &--ticked {
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEzIDEwIj48cGF0aCBmaWxsPSIjRkZGIiBkPSJNMTIuMjA3IDEuNUw4LjcwNyA1bDMuNS0zLjVMMTEuNS43OTMgOCA0LjI5M2wzLjUtMy41VjEuNWguNzA3ek0yLjI5MyA3TDQuNSA5LjIwN2wuMzU0LS4zNTMtLjM1NC4zNTNMMi4yOTMgN3ptMi4yMDcuNzkzbDctNyAuNzA3LjcwN0w0LjUgOS4yMDcuNzkzIDUuNWwuNzA3LS43MDcgMyAzeiIvPjwvc3ZnPg==") no-repeat 17px 50% !important;
    }

    &--delete {
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE2IDE3Ij4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGZpbGw9IiNGRkYiPiAgICAgICAgICAgIDxwYXRoIGQ9Ik05LjMwOCA4aDEuMDc3djVIOS4zMDh6TTUuMzA4IDhoMS4wNzd2NUg1LjMwOHoiLz4gICAgICAgIDwvZz4gICAgICAgIDxwYXRoIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTTMuMTU0IDRWMWg5LjY5MnYzTTEgNGgxNE0yLjA3NyA0LjQ4TDMuMTU0IDE2aDkuNjkybDEuMDc3LTEyIi8+ICAgIDwvZz48L3N2Zz4=") no-repeat 17px 50% !important;
    }

    &--rename {
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNGRkYiPiAgICAgICAgPHBhdGggc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTTkuMDU3IDFIMXYxNGgxNFY3LjQ0NCIvPiAgICAgICAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2Utd2lkdGg9Ii4xNSIgZD0iTTE1LjA0NiAxLjkzNmMtLjY0NC0uNjQ0LTEuNzY2LS42NDQtMi40MSAwTDYuOTIyIDcuNjVhLjQyNS40MjUgMCAwIDAtLjExMi4xOThsLS42MDIgMi40MDlhLjQyOC40MjggMCAwIDAgLjQxMy41My40MTQuNDE0IDAgMCAwIC4xMDMtLjAxM2wyLjQxLS42MDNhLjQyMS40MjEgMCAwIDAgLjE5Ny0uMTEybDUuNzE1LTUuNzE0Yy4zMjEtLjMyMi40OTktLjc1LjQ5OS0xLjIwNSAwLS40NTUtLjE3Ny0uODgyLS41LTEuMjA0ek04LjgxMyA5LjM3NGwtMS42MDcuNDAxLjQwMi0xLjYwNiA0LjcyNy00LjcyNyAxLjIwNSAxLjIwNC00LjcyNyA0LjcyOHptNS42My01LjYzMmwtLjMuMzAxLTEuMjA1LTEuMjA1LjMtLjNhLjg3My44NzMgMCAwIDEgMS4yMDUgMGMuMTYxLjE2LjI1LjM3NC4yNS42MDJhLjg0Ni44NDYgMCAwIDEtLjI1LjYwMnoiLz4gICAgPC9nPjwvc3ZnPg==") no-repeat 17px 50% !important;
    }

    &--export {
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE4IDE1Ij4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNGRkYiPiAgICAgICAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2Utd2lkdGg9Ii4yNSIgZD0iTTE1LjU5OCA4LjA2OEg2LjVhLjUuNSAwIDEgMSAwLTFoOS4yODVsLTEuMTctMS40MzIuNzctLjYzNiAyLjAzNyAyLjM4NC4wMzUuMDM3LTIuMDcxIDIuNDY4LS43NzItLjYzNS45ODQtMS4xODZ6Ii8+ICAgICAgICA8cGF0aCBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41IiBkPSJNMTMgMTAuODkzVjE0SDFWMWgxMnYzLjE1NSIvPiAgICA8L2c+PC9zdmc+") no-repeat 17px 50% !important;
    }

    &--notchecked {
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE1IDE1Ij48cGF0aCBmaWxsPSIjRkZGIiBkPSJNMSAxLjk5MnYxMS4wMTZjMCAuNTQ3LjQ0NS45OTIuOTkyLjk5MmgxMS4wMTZjLjU0NyAwIC45OTItLjQ0NS45OTItLjk5MlYxLjk5MmMwLS41NDctLjQ0NS0uOTkyLS45OTItLjk5MkgxLjk5MkMxLjQ0NSAxIDEgMS40NDUgMSAxLjk5MnptLTEgMEMwIC44OTIuODkzIDAgMS45OTIgMGgxMS4wMTZDMTQuMTA4IDAgMTUgLjg5MyAxNSAxLjk5MnYxMS4wMTZjMCAxLjEtLjg5MyAxLjk5Mi0xLjk5MiAxLjk5MkgxLjk5MkMuODkyIDE1IDAgMTQuMTA3IDAgMTMuMDA4VjEuOTkyeiIvPjwvc3ZnPg==") no-repeat 17px 11px !important;

        &-2 {
            background-position: 17px 11px !important;
        }
    }

    &--checked {
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE1IDE1Ij48ZyBmaWxsPSIjRkZGIj48cGF0aCBkPSJNNS43MDcgMTBsNy03IC43MDcuNzA3LTcuNzA3IDcuNzA3TDIgNy43MDcgMi43MDcgNyIvPjxwYXRoIGQ9Ik0xIDEuOTkydjExLjAxNmMwIC41NDcuNDQ1Ljk5Mi45OTIuOTkyaDExLjAxNmMuNTQ3IDAgLjk5Mi0uNDQ1Ljk5Mi0uOTkyVjEuOTkyYzAtLjU0Ny0uNDQ1LS45OTItLjk5Mi0uOTkySDEuOTkyQzEuNDQ1IDEgMSAxLjQ0NSAxIDEuOTkyem0tMSAwQzAgLjg5Mi44OTMgMCAxLjk5MiAwaDExLjAxNkMxNC4xMDggMCAxNSAuODkzIDE1IDEuOTkydjExLjAxNmMwIDEuMS0uODkzIDEuOTkyLTEuOTkyIDEuOTkySDEuOTkyQy44OTIgMTUgMCAxNC4xMDcgMCAxMy4wMDhWMS45OTJ6Ii8+PC9nPjwvc3ZnPg==") no-repeat 17px 11px !important;

        &-2 {
            background-position: 17px 11px !important;
        }
    }

    &--muted {
        opacity: .3;
    }

    &--notification {
        font-size: 9px !important;
        text-transform: uppercase;
        background: none !important;
        line-height: 20px !important;
        padding-top: 0 !important;

        &:hover {
            text-decoration: underline !important;
        }
    }

    &--with-margin {
        margin-bottom: 10px !important;
    }
}

.c-iframe-dialog {
    &__body {
        height: calc(100vh - 150px) !important;
    }
    &.c-iframe-idibu {
        .c-iframe-dialog__body {
            height: calc(100vh - 320px) !important;
        }
        iframe {
            height: calc(100% - 120px) !important;
        }
    }
    iframe {
        height: calc(100% - 2px);
        border: 0;
        margin-top: 1px;
    }
}

/*------------------------------------*\
    #React text editor
\*------------------------------------*/
.c-text-editor {
    font-family: $font-stack---open-sans;

    .EditorToolbar__root___3_Aqz {
        padding: 10px 5px 5px;

        & > div:nth-child(2) {
            display: none;
        }
    }

    .public-DraftStyleDefault-pre {
        padding: 0;
        border: none;
        background-color: transparent;
    }

    .RichTextEditor__editor___1QqIU {
        font-family: $font-stack---open-sans;
        font-size: 16px;
        padding: 30px 5px;
    }

    .RichTextEditor__editor___1QqIU .RichTextEditor__paragraph___3NTf9 {
        margin: 25px 0;
    }

    .RichTextEditor__editor___1QqIU .public-DraftEditor-content {
        min-height: 100px;
    }

    .RichTextEditor__editor___1QqIU .public-DraftEditorPlaceholder-root {
        color: #c7c7c7;
    }
}

/*------------------------------------*\
    #Edited text style
\*------------------------------------*/
.c-text-edited {
    margin-bottom: 15px;
    ul {
        margin: 16px;
        list-style-position: inside;
        li {
            list-style: disc;
            margin-bottom: 6px;
        }
    }

    a {color: $brand;}

    code {
        background-color: rgb(243, 243, 243);
        font-size: 16px;
        padding: 2px;
        font-family: Inconsolata, Menlo, Consolas, monospace;
    }

    blockquote {
        border-left: 5px solid #eee;
        color: #666;
        font-style: italic;
        margin: 16px 0;
        padding: 10px 20px;
    }
}

/*------------------------------------*\
    #Employer intro steps
\*------------------------------------*/
.c-intro-box {
    text-align: center;
    padding: 30px 0;

    @include bp(tab) {
        padding: 40px 60px 10px;
    }

    &--v2 {
        padding: 30px 0 10px;

        @include bp(tab) {
            padding: 60px 0 30px;
        }

        .c-intro-box__img {
            margin-bottom: 30px;

            @include bp(tab) {
                margin-bottom: 60px;
            }
        }
    }

    &__title {
        text-align: center;
        font-size: 20px!important;
        @include bp(tab) {
            font-size: 32px!important;
            padding: 25px 0 !important;
        }
    }

    &__txt {
        font-range: 320px 1280px;
        font-size: responsive 16px 20px;
        color: $black-63;
        line-height: 1.6;
        margin: 35px 0 0;
    }

    &__video {
        margin: 0 auto;
        padding: 100px 20px 40px;
        background: linear-gradient(44.51deg, #00A0FC 0%, #00DDFC 100%);
        color: #fff;
        font-size: 20px;
    }


    &__video-frame {
        @include bp(tab) {
            width: 448px;
            height: 252px;
            margin: 0 auto;
        }

        iframe {border: 0;}
    }

    &__btn {
        @include bp(tab) {
            margin: 6px;
            min-width: 150px !important;
        }
    }

    &__img {
        height: 160px;
        @include bp(tab) {
            height: 220px;
        }
    }
}

/*------------------------------------*\
    #Chip
\*------------------------------------*/
.c-chip {
    background: rgba(#000, .1) !important;
    color: $black-63 !important;
}

/*------------------------------------*\
    Opaque modal overlay
\*------------------------------------*/
.c-overlay--grey {
    background-color: #333 !important;
}
// title
.c-overlay__ubertitle {
    width: 100%;
    color: #A9AAAE;
    letter-spacing: 0.16px;
    text-align: center;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    padding: 20px 50px;
    font-size: 24px;
    margin-bottom: 50px;
    position: relative;
    background-color: #292A2D;

    @include bp(tab) {
        padding: 20px;
    }

    small {
        margin-top: 5px;
        display: block;
        text-align: center;
        font-size: 14px;
    }
}
// close
.c-overlay__close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 0;
    width: 29px;
    height: 29px;
    background: transparent url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOSIgaGVpZ2h0PSIyOSIgdmlld0JveD0iMCAwIDI5IDI5Ij48cGF0aCBmaWxsPSIjRkZGIiBkPSJNMTQuNSAxMS42NzJMMy40MTQuNTg2Yy0uNzgtLjc4LTIuMDQ3LS43OC0yLjgyOCAwLS43OC43OC0uNzggMi4wNDcgMCAyLjgyOEwxMS42NzIgMTQuNS41ODYgMjUuNTg2Yy0uNzguNzgtLjc4IDIuMDQ3IDAgMi44MjguNzguNzggMi4wNDcuNzggMi44MjggMEwxNC41IDE3LjMyOGwxMS4wODYgMTEuMDg2Yy43OC43OCAyLjA0Ny43OCAyLjgyOCAwIC43OC0uNzguNzgtMi4wNDcgMC0yLjgyOEwxNy4zMjggMTQuNSAyOC40MTQgMy40MTRjLjc4LS43OC43OC0yLjA0NyAwLTIuODI4LS43OC0uNzgtMi4wNDctLjc4LTIuODI4IDBMMTQuNSAxMS42NzJ6IiBvcGFjaXR5PSIuNSIvPjwvc3ZnPg==') no-repeat 50% 50%;
    @include bp(tab) {
        top: 50%;
        margin-top: -15px;
        right: 30px;
    }
}

@media screen and (max-width: 500px) {
    .c-fullscreenmodal__footer {
        margin-bottom: 70px;
    }
}
.c-fullscreenmodal {
    &__footer {
        border-top: 1px solid rgba(255,255,255,0.1) !important;
        color: rgba(255,255,255,0.63);
        background: #292A2D;
        margin-top: 50px;
        padding: 20px !important;
        width: 100%;
        z-index: 1;
        text-align: center;

        div {
            text-align: center;
        }

        button {
            display: inline-block;
            font-size: 18px;
            color: #fff;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid rgba(255,255,255,0.7);
            line-height: 30px;
            margin: 0 7px;
            text-align: center;

            &:hover,
            &.is-active {
                background: #fff;
                color: #333;
            }
        }
    }
}

.c-checkbox-with-input {
    position: relative;
    margin-bottom: 15px;

    &_input {
        display: flex;
        align-items: center;
        text-align: center;
        position: absolute;
        top: 23px;
        width: 60px;
        right: 40px;
        color: #fff;
    }
}


//label style
.c-label {
    color: rgba(#000, .5);
    font-size: 12px;
    text-transform: uppercase;
}

//sidebar in create script
.c-scrollbar {
    height: 170px;
    font-family: $font-stack---open-sans;
    margin-bottom: 50px;
    @include bp(lap) {
        min-height: 470px;
        margin-bottom: 0;
    }
}

/*-------------------------------------------------*\
    For Chart IO
\*-------------------------------------------------*/

.recharts-surface {
    overflow: visible !important;
}

.recharts-cartesian-axis-tick text {
    font-family: "Open Sans", sans-serif;
}

/*-------------------------------------------------*\
    For ziggeo
\*-------------------------------------------------*/
.ba-recorder-blue-color .ba-videorecorder-chooser-button-0, .ba-recorder-blue-color .ba-videorecorder-chooser-button-2, .ba-recorder-blue-color .ba-videorecorder-imagegallery-leftbutton, .ba-recorder-blue-color .ba-videorecorder-imagegallery-rightbutton {
    background-color: $brand !important;
}

.ba-recorder-blue-color .ba-videorecorder-chooser-button-0, .ba-recorder-blue-color .ba-videorecorder-chooser-button-2, .ba-recorder-blue-color .ba-videorecorder-imagegallery-image:hover {
    border-color: $brand !important;
}
.ba-recorder-blue-color .ba-recorder-loader-label, .ba-recorder-blue-color .ba-videorecorder-button-circle-selected, .ba-recorder-blue-color .ba-videorecorder-button-inner:hover, .ba-recorder-blue-color .ba-videorecorder-chooser-button-1, .ba-recorder-blue-color .ba-videorecorder-chooser-button-3, .ba-recorder-blue-color .ba-videorecorder-chooser-icon-container {
    color: $brand !important;
}

.ba-videorecorder-container > img {
    max-height: 100%;
    max-width: 100%;
}

.ba-player-space-theme-message-container {
    display: flex;
    height: 100%;
    z-index: 10;
    align-items: center;
    color: white;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
}

.ba-recorder-overlay {
    position: static!important;
}

.ba-videorecorder-circle-button {
    display: none;
}

.ba-videoplayer-video {
    max-height: 500px;
    margin: auto;
    max-width: 100%;
    width: auto !important;
}

.ba-videoplayer-container {
    max-height: 500px;
    min-width: 200px !important;
    min-height: 200px !important;
    max-width: 100%;
}

.ba-audioplayer-container {
    min-width: 200px !important;
}

li.appcues-nothing-new {
    display: none;
}

.appcues-widget-icon.appcues-icon-visible {
    img {
        max-width: 21px;
    }
}

.appcues-widget {
    margin-bottom: 6px;
}

