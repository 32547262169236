/*------------------------------------*\
    #Cards on opportunities page
\*------------------------------------*/
@keyframes dot-animation {
  to { width: 100%; }
}

.c-card {
  position: relative;
  display: block;
  background: #fff;
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 31px;
  color: #00283f;

  &:hover, &:focus {
    color: #00283f;
  }

  .c-img-placeholder {
    background: white;
    color: #00283f;
    border: 1px solid #00283f;
    border-radius: 50%;
  }

  &__disabled {
    opacity: 0.4;
  }

  &--transparent {
    background: none;
    box-shadow: none !important;

    img,
    .c-img-placeholder {
      border-radius: 50% !important;
    }
  }

  &__body {
    padding: 7px 6px 7px 5px;
    line-height: 16px;
  }

  &__label {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #f02e2e;
    border: 2px solid white;
  }

  /* name */
  &__name {
    width: 95%;
    text-align: left;
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* popover icon */
  &__popover-icon {
    svg {
      color: $black-54 !important;
    }
  }

  /* status */
  &__status {
    font-size: 9px;
    text-transform: uppercase;
  }

  &__ai-score {
    width: 121px;
    display: flex;
    justify-content: flex-start;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__ai-score-icon {
    cursor: pointer;
  }

  &__grade-manually {
    margin-top: 10px;
    font-size: 11px;
    color: #00283f;
    padding-left: 14px;
}

  &__ai-score-dots_wrapper {
    max-width: 9px;
  }

  &__ai-score-dots {
    width: 0;
    overflow: hidden;
    animation: dot-animation 3s steps(3, start) infinite;
  }

  &__score-box {
    min-height: 21px;
    line-height: 1;
    display: flex;
    align-items: center;
    padding: 0 14px;
    font-size: 12px;

    &--v2 {
      border-top: 0;
      padding: 0 8px 0 0;
      justify-content: flex-start;

      @include bp(tab) {
        justify-content: space-between;
      }

      .c-card__judges {
        .c-card__judge {
          border-width: 2px;
          margin-right: -10px;

          .c-img-placeholder {
            font-size: 12px;
          }
        }
      }
    }

    .c-card__score {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #292A2D;

      &-percentage {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  &__judges {
    display: flex;

    .c-card__judge {
      position: relative;
      margin-right: -5px;
      border: 1px solid #fff;
      border-radius: 50%;
      overflow: auto;
      z-index: 1;
      font-size: 0;

      &:last-of-type {
        margin-right: 0;
      }

      .c-img-placeholder {
        border-radius: 50%;
        font-size: 8px;
        font-weight: 700;
      }

      img {
        vertical-align: top;
        border-radius: 50%;
      }
    }
  }
}

/*------------------------------------*\
    #Candidate full info cards (modal)
\*------------------------------------*/
.c-card-big {
  &__public {
    max-width: 910px;
    padding: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;

    &-profile {
      padding-top: 40px;
      border-top: solid 1px #dadada;

      &--title {
        margin: 0;
        padding-left: 20px;
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        color: rgba(0, 0, 0, 0.87);

        @include bp(lap) {
          padding-left: 50px;
        }
      }
    }
  }

  &__header {
    font-family: $font-stack---open-sans;
    position: relative;
    text-align: center;
    padding-top: 40px;
    background: #f7f7f7;
    border-bottom: 1px solid #dadada;
    display: flex;
    min-height: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    @include bp(lap) {
      text-align: left;
      display: block;
      padding: 40px;
      padding-bottom: 30px;
      background: #ffffff;

      &_public {
        padding: 40px;
        padding-bottom: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }

    &_public {
      background: #ffffff;
    }

    .o-mediablock__body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 100%;
      z-index: 1;

      &_public {
        margin-top: 0;
      }

      @include bp(lap) {
        width: auto;
        align-items: flex-start;
      }
    }

    &-close {
      background: transparent;
      border: none;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0;

      @include bp(lap) {
        top: 30px;
        right: 30px;
      }
    }

    &-test {
      font-size: 12px;
      padding: 4px 15px;
      background: $blue;
      color: white;
      margin-left: 7px;
      border-radius: 4px;
    }

    &-img {
      margin: 10px 0 15px;
      @include bp(lap) {
        margin: 0 12px 0 0;
      }
      .c-img-placeholder {
        background: white;
        color: black;
        border: 3px solid black;
        border-radius: 50%;
      }
    }

    &-data {
      width: 100%;
      @include bp(lap) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      &_public {
        padding: 20px;
      }
    }

    &-user {
      &_public {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &-name-wrapper {
      align-self: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      line-height: 1.2;
      margin-bottom: 5px;

      @include bp(lap) {
        align-self: flex-start;
      }
    }

    &-name {
      font-size: 24px;
      white-space: nowrap;
      color: #000000;

      font-weight: 800;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.87);

      &_public {
        font-size: 24px;
        font-style: normal;
        line-height: normal;
        color: rgba(0, 0, 0, 0.87);
      }
    }

    &-position {
      white-space: normal;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.38;
      color: #000000;
      margin-top: 5px;
    }

    &-detail-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 10px;

      &:first-child {
        margin-top: 25px;
      }

      @include bp(lap) {
        &:first-child {
          margin-top: 0;
        }

        &:not(:last-child) {
            margin-right: 30px;
            margin-bottom: 0;
        }
      }

      &--head,
      &--value {
        text-align: left;
        font-size: 11px;
        line-height: 1.45;
      }

      &--head {
        margin-bottom: 3px;
        text-transform: uppercase;
        color: rgba(8, 8, 8, 0.5);
      }

      &--value {
        text-transform: initial;
        color: rgba(0, 0, 0, 0.87);
      }
    }

    &-email {
      position: relative;
      padding: 0;
      display: inline-flex;
      border: none;
      min-width: 160px;
      background: none;

      &--text {
        display: inline-block;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--btn {
        position: absolute;
        top: -15px;
        right: 0;
        display: block;
        padding: 1px 3px;
        color: #1247F6;
        font-size: 0px;
        font-weight: 600;
        text-transform: capitalize;
        line-height: 1.5;
      }

      &:hover .c-card-big__header-email--text {
        background: linear-gradient(to left, transparent 15%, #000000);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:hover .c-card-big__header-email--btn {
        color: #1247F6;
        font-size: 12px;
        top: -2px;
        right: 0;
      }
    }

    &-details {
      font-family: $font-stack---open-sans;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 45px;
      width: 100%;
      background: #ffffff;
      opacity: 1;
      padding: 23px 25px;
      padding-top: 0;

      @include bp(lap) {
        margin-top: 10px;
        padding: 0;
        flex-direction: row;
        justify-content: flex-start;

        & .c-card-big__header-detail-info {
            margin-right: 30px;
        }
      }

      &_half {
        line-height: 18px;
        text-align: left;
        margin-right: 0;

        &_public {
          font-size: 11px;
          line-height: 1.64;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.87);

          span:first-child {
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.5);
          }
        }

        @include bp(lap) {
          width: 56%;
        }

        &:first-child {
          @include bp(lap) {
            margin-right: 58px;
            width: 60%;
          }
          span {
            min-width: 50px;
          }
        }

        .c-label {
          display: inline-block;
          color: $black-54;
          margin-right: 10px;
        }

        span {
          text-align: left;
          min-width: 80px;
        }
      }

      &_public {
        display: flex;
        flex-direction: column;
        margin-top: 12px;
      }
    }

    &-actions {
      display: flex;
      order: 1;
      flex-direction: column;
      align-items: center;
      @include bp(lap) {
        align-items: flex-end;

        &_public {
          height: 55px;
        }
      }

      .icon {
        border: none;
        background: transparent;
        padding: 0 4px;
        &:hover {
          cursor: pointer;

          svg circle {
            fill: rgb(0, 160, 252);
            fill-opacity: 0.1;
          }
        }
      }
    }

    &-btn {
      margin-top: 10px;
      width: calc((100% - 10px) / 2);
      display: block;
      border-radius: 3px;
      &:first-child {
        margin-top: 0;
      }
      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }

  &__mobile-background {
    height: 69%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    filter: blur(10px);
    opacity: 0.15;
  }

  &__subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 32px;
    margin-bottom: 20px;

    &-job {
      width: 100%;
      display: flex;
      flex-direction: column;

      &--title {
        display: inline-block;
        margin: 0;
        margin-bottom: 6px;
        font-family: inherit;
        font-size: 18px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.87);
        text-transform: capitalize;
      }

      &--invite-btn {
        margin-top: 32px;
      }

      &--hidden-details {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin: 0;
        margin-bottom: 16px;
        font-family: inherit;
        font-size: 11px;
        line-height: 1.45;
        color: rgba(0, 0, 0, 0.5);
        text-transform: uppercase;

        button {
            padding: 0;
            margin-left: 5px;
            color: #1247F6;
            text-transform: capitalize;
            border: none;
            background: none;
        }
      }

      &--details {
        display: inline-block;
        margin: 0 0 16px;
        font-family: inherit;
        color: rgba(0, 0, 0, 0.5);
        text-transform: uppercase;

        font-size: 10px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.8px;
        color: #9fa0a5;

        span {
          display: block;
        }
        span:nth-child(2n) {
          display: none;
        }

        @include bp(lap) {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 0;
          width: initial;

          span,
          span:nth-child(2n) {
            width: initial;
            margin-right: 1ch;
            display: inline-flex;
          }

          span:not(:nth-child(2n)) {
            display: inline-block;
            cursor: pointer;
          }

          span:first-child {
              cursor: initial;
          }
        }
      }
      @include bp(tab) {
        width: 70%;
      }
    }

    &-btns {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      button:only-child {
        margin-left: auto;
      }

      @include bp(tab) {
        width: 30%;
        justify-content: flex-end;

        button {
          width: 120px;
        }
      }
    }
  }

  &__subheader-tooltip {
    font-size: 10px !important;
    color: #ffffff !important;
    padding: 2px 10px !important;
    background-color:#00283f !important;
    max-width: 200px !important;
    border-radius: 2px !important;
    line-height: 2 !important;
    text-transform: capitalize !important;
    opacity: 0.89 !important;


    &.place-top:after {
        border-top-color:#00283f !important;
    }

    &.place-bottom:after {
        border-bottom-color:#00283f !important;
    }
  }

  &__dialog {
    padding-top: 0px !important;

    & > div:first-child {
      height: 100%;
      overflow: auto;
      z-index: 1500;
      position: relative;
      padding-top: 16px;
    }
  }

  &__mobile-dialog {
    position: absolute !important;
    top: -80px !important;
    height: calc(100% + 80px) !important;
  }

  &__job {
    &-wrapper {
      font-family: $font-stack---open-sans;
      position: relative;
      margin-bottom: 20px;
      border-radius: 4px;
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    }

    &-info {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
    }
  }

  &__opportunity-wrapper {
    margin-bottom: 40px;
  }

  &__dots-btn {
    position: absolute;
    top: 17px;
    right: 10px;
    cursor: pointer;
  }

  &__menu-item {
    font-family: $font-stack---open-sans;
    border-radius: 3px;
    font-size: 11px;

    &--enabled div {
      color: rgba(255, 255, 255, 1);

      &:hover {
        background-color: transparent;
      }

      &:focus {
        outline: none;
      }
    }

    &--disabled div {
      color: rgba(255, 255, 255, 0.67);
    }
  }

  &__text {
    display: flex;
    flex-direction: column;

    &-title {
      margin-bottom: 19px;
    }

    &-details {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      &--wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        &:first-child {
          width: 100%;
          margin-bottom: 11px;
        }

        &:not(:last-of-type) {
          margin-right: 40px;
        }

        @include bp(tab) {
          &:first-child {
            width: initial;
            margin-bottom: 0;
          }
        }
      }

      &--label,
      &--value {
        display: inline-block;
        margin: 0;
        font-family: inherit;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: 0.2px;
        color: #3d3f47;
      }

      &--label {
        margin-bottom: 3px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.8px;
        color: #9fa0a5;
      }

      &--value {
        min-height: 21px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        color: rgba(0, 0, 0, 0.87);

        &.simple {
            text-transform: none;
        }

        .dot-separator {
            margin: 0 3px;
        }
      }

      &--status {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-left: 4px;
        border-radius: 50%;
        background-color: #fc5d00;
      }
    }
  }

  &__commentlink {
    @include bp(tab) {
      position: absolute;
      bottom: 6px;
      right: 30px;
    }
  }

  &__question-group-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 23px;
    font-size: 14px;
    color: #00283f;
    padding-right: 30px;

    > div:not(:last-child) {
      margin-bottom: 13px;
    }
  }

  &__interviews {
    font-family: $font-stack---open-sans;
    padding: 0 20px;

    @include bp(lap) {
      padding: 0 40px;

      &_public {
        padding-bottom: 40px;
      }
    }
  }

  &__comment-label {
    font-family: $font-stack---open-sans;
      font-size: 11px !important;

      @include bp(tab) {
          font-size: 14px !important;
      }
  }

  &__btn-common {
    width: 180px;
    margin-right: 20px;
  }

  &__profile-content {
    font-size: 14px;
    padding: 0 20px 40px;
    border-bottom: solid 1px #dadada;

    @include bp(lap) {
      padding: 0 50px 45px;
    }

    &_public {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    p {
      line-height: 1.71;
      color: rgba(0, 0, 0, 0.5);
    }

    .date {
      opacity: 0.8;
    }
  }

  &__buttons {
    background: rgba(243, 243, 243, 0.74);
    padding: 28px 45px;
    text-align: center;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    @include bp(tab) {
      text-align: right;
    }

    button {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      color: #1247F6;
      border: none;
      background: transparent;
      text-transform: uppercase;
      padding: 5px 10px;
    }
  }

  .o-box-grey {
    border-left: 5px solid transparent;
    padding: 15px;
    padding-right: 0;
    background-color: #ffffff;

    .c-card-big__text {
      width: 100%;
      @include bp(tab) {
        flex: 1;
      }
    }
    .c-card-big__actions {
      margin-top: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      .c-btn__ghost-blue {
        margin-top: 20px;
      }
      @include bp(tab) {
        flex-direction: row;
        margin-top: 0;
        justify-content: space-between;
        align-items: center;

        .c-btn__ghost-blue {
          margin-top: 0;
        }
      }

      .c-card-big__score {
        font-size: 18px;
        line-height: 1.2;
        position: relative;
        @include bp(tab) {
          width: 225px;
          text-align: right;
        }
      }

      .c-card__judges {
        @include bp(tab) {
          justify-content: flex-end;
        }
      }
    }

    .c-card-big__script {
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 0.5);
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.8px;
      color: #9fa0a5;

    }

    &--autograde {
      border-left: 4px solid $yellow;

      .c-card-big__script {
        color: $yellow;
      }
    }
  }

  &__team-score {
    text-transform: none;
    display: flex;
    align-items: center;
  }

  &__score-judges {
    display: flex;
  }

  &__judges {
    display: flex;
    cursor: pointer;
  }

  &__judge {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 50%;
    margin-right: -8px;
    overflow: auto;
    z-index: 1;
    font-size: 8px;
    color: white;
    min-width: 20px;
    min-height: 20px;
    font-weight: 600;
    background-color: #1247F6;

    &.guest {
      background-color: #436cff;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__tooltip {
    max-width: 212px;
    padding: 0 !important;
    border-radius: 3px;
    background-color: #ffffff;
    line-height: 20px;
    color: $black-87;
    text-align: left;
    pointer-events: auto !important;
    font-weight: 400;
    font-size: 14px !important;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    white-space: normal;
    z-index: 10000 !important;
    &.show {
      opacity: 1 !important;
    }

    &:hover {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }

  &__tip-judges {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 14px 0;
  }

  &__tip-judge {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 17px;
    padding-left: 10px;
    padding-top: 9.5px;

    &:not(:last-child) {
      padding-bottom: 9px;
      border-bottom: 1px solid rgba(151, 151, 151, 0.12);
    }
  }

  &__tip-photo {
    .c-img-placeholder {
      border-radius: 50%;
      font-size: 10px;
    }
  }

  &__tip-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--name {
      font-size: 11px;
      margin-left: 16px;
      margin-right: 45px;
      width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      overflow: hidden;
    }
  }
}

.message-candidate {
  &__subheader {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }
}
.message-button,
.share-button {
  margin-left: 10px;
}
.message-modal {
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(242, 242, 242, 0.5);
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 50px;
    padding-top: 30px;
    svg {
      position: absolute;
      top: 24px;
      right: 32px;
      cursor: pointer;
    }
  }
  &__editor {
    width: 100%;
  }
  &__field {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &--label {
      width: 100%;
      margin-bottom: 8px;
      font-weight: normal;
      font-size: 12px;
      line-height: 1.33;
      color: rgba(0, 0, 0, 0.5);
      text-transform: uppercase;
    }
    &--email,
    &--email:disabled,
    &--text {
      width: 100%;
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      border: none;
      background-color: transparent;
    }

    &--email {
      color: rgba(0, 0, 0, 0.4);
      cursor: default;

      .dark {
        color: rgba(0, 0, 0, 0.8);
      }
    }

    &--text,
    &--text:focus {
      outline: none;
      color: rgba(0, 0, 0, 0.8);
    }
  }
  &__line {
    border-top: 2px solid #979797;
    margin: 7px 50px;
  }
  &__subject-limit {
    width: 100%;
    float: right;
    padding-right: 50px;
    padding-bottom: 38px;
    text-align: right;
    font-size: 11px;
    line-height: 1.45;
    color: rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    border-bottom: 2px solid #efefef;
  }
  &__body {
    background-color: rgba(0, 0, 0, 0.02);
    padding: 80px 100px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    padding: 40px 45px;
    border-radius: 4px;
    background-color: #ffffff;
  }

  &__logo {
    margin-bottom: 29px;
  }

  .RichTextEditor__editor___1QqIU {
    max-width: 640px;
    font-size: 16px;
    font-family: $font-stack---open-sans;
    color: #4a4a4a;
  }

  &__footer {
    padding: 22px 0 !important;
  }
}

/*------------------------------------*\
    #Grade modal header
\*------------------------------------*/
.c-grade-card__header {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 20px 30px;
  min-height: 120px;
  flex-direction: column;
  @include bp(tab) {
    flex-direction: row;
    align-items: center;
    min-height: auto;
    justify-content: center;
  }
}
