/*------------------------------------*\
    #Links
\*------------------------------------*/
.c-link {
    cursor: pointer;

    /* blue link */
    &--blue {
        color: $blue;
        &:hover {color: rgb(12, 49, 172);}
    }

    &--brand {
        color: $blue;
        outline: none;
        &:hover {color: $blue-dark;}
    }

    &--inherit {
        color: $grey-dark;
        &:hover {color: $grey-60;}
    }

    &--white {
        color: #fff;
        &:hover {
            color: #fff;
            border-bottom: 1px solid #fff;
        }
    }

    &--grey {
        padding: 0;
        border: 0;
        background: none;
        color: $grey9;
        &:hover {color: $grey-dark;}
    }

    &--whitestatic {
        color: #fff;
        &:hover {color: #fff;}
    }
}
