/* ------------------------------------------------------- *\
      #Checkboxes and radios
\* ------------------------------------------------------- */

// Controls
input[type="checkbox"].u-muted-0, input[type="radio"].u-muted-0 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; /* so, label always have cursor pointer */
}

// Labels
input[type="checkbox"] + label, input[type="radio"] + label {
    position: relative;
    cursor: pointer;
    line-height: 1.4;
    margin-bottom: 0;
    padding-left: 25px;
    font-weight: normal;
}


input[type="checkbox"] + label, input[type="radio"] + label {
  &:before {
    display: block;
    position: absolute;
    top: -3px;
    left: 0;
  }
}

input[type="checkbox"] + label {
    &:before {
        content: "\e900";
        font-size: 18px;
        color: $blue;
    }
}
// input[type="radio"] + label {
//   &:before { content: url('../img/svg/radio-checked-not.svg'); }
// }
// input[type="radio"] + label.l--small {
//   &:before { content: url('../img/svg/radio-small-checked-not.svg'); }
// }

input[type="checkbox"]:checked + label {
    &:before {
        content: "\e905";
    }
}
input[type="radio"]:checked {
    pointer-events: none !important;
}
// input[type="radio"]:checked + label.l--small {
//   &:before { content: url('../img/svg/radio-small-checked.svg'); }
// }

// input[type="checkbox"]:disabled + label, input[type="radio"]:disabled + label {
//   cursor: not-allowed !important;
// }

/*------------------------------------*\
    # phone number field
\*------------------------------------*/
.phone-input {
    position: relative;
    margin: 0 8px 0 0;

    .input[type=tel] {
        border: none;
        border-radius: 0px;
        box-shadow: none;
        width: 100%;
        background: transparent;
        padding-left: 0;
        padding-right: 0;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.87);
    }

    .button {
        background-color: transparent;
        border: none;
        border-radius: 0;

        &.open-dropdown, &:hover .selected-flag, &.open-dropdown .selected-flag {
            background-color: transparent;
        }
    }

    .label {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        padding: 0;
        letter-spacing: 0.6px;
        font-family: $font-stack---open-sans;
        color: rgb(159, 160, 165);
    }

    .hr-bottom {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        bottom: 0px;
        box-sizing: content-box;
        margin: 0px;
        position: absolute;
        width: 100%;
        z-index: 100;
        &.blue {
            border-bottom: 2px solid rgb(0, 160, 252);
            transform: scaleX(0);
            transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
            &:not(.error).active {
                transform: scaleX(1);
            }
        }
        &.error {
            border-bottom: 1px solid rgb(244, 67, 54);
        }
    }
};

.cut-text {
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-box;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
