/*------------------------------------*\
    #Create scripts (interviews)
\*------------------------------------*/
.c-multicol {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 6px;

    @include bp(lap) {
        flex-direction: row;
    }

    &__num {
        display: flex;
        flex: 0 1 30px;
        align-items: center;
        justify-content: flex-start;
    }

    &__col {
        display: flex;
        flex: 1;
        align-items: center;
    }
}

/*------------------------------------*\
    #Script settings score toggle
\*------------------------------------*/
.c-score-toggle {
    font-size: 14px;
    margin-left: 34px;
}
