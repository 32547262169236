//  ------------------------------------
//  #Borders
//  ------------------------------------

// example: u-brdr--2
$border-sizes: ( 2 );
@each $name in $border-sizes {
    %u-brdr--t-#{$name},
    .u-brdr--t-#{$name} {
        border-top: #{$name}px solid $grey-60;
    }
}

.u-brdr--none {border: 0 !important;}

//used on landing page (remove after redesign of page)
.b {
    &__top-bottom {
        @include border(1px solid, $grey-e5, top bottom);
    }
}
