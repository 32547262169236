img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    border: 0;
}

canvas {
    max-width: 100% !important;
}
