/*------------------------------------*\
    #User account drop down
\*------------------------------------*/
.c-user-popover {
    &__header {
        padding: 16px 0 30px;

        &-img {
            position: relative;
            overflow: hidden;
            z-index: 10;
            margin-right: 16px;
            border-radius: 50%;
            height: 80px;
        }

        .c-upload {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            font-size: 10px;
            color: #fff;
            height: 26px;
            background-color: rgba($brand-dark, .6);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-company {
            padding: 10px 0;

            @include bp(tab) {
                padding: 30px 0;
            }
        }
    }
}

/*------------------------------------*\
    #Test interview popover
\*------------------------------------*/

.c-link-tooltip {
    color: $brand;
    text-decoration: underline;

    &:hover {
        color: $brand !important;
        text-decoration: underline !important;
    }
}

/*------------------------------------*\
    #Export candidates popover
\*------------------------------------*/
.c-export {
    &__content {
        max-width: 560px !important;
        width: 100% !important;
        > div {
            border-radius: 7px !important;
            box-shadow: 0 0 39px 0 rgba(0, 0, 0, 0.23) !important;
        }
    }

    &__body {
        padding: 0 !important;
    }

    &__body-inner {
        padding: 35px 15px 0px;
        color: #4a4a4a;

        @include bp(tab) {
            padding: 35px 30px 25px;
        }
    }

    &__section {
        position: relative;
    }

    &__header {
        font-size: 18px;
        font-weight: bold;
        line-height: 21px;
        margin-bottom: 60px;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        width: 21px;
        height: 21px;
        background: transparent url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiI+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBpZD0iYSIgZD0iTTcuMDUxIDZMMTIgMTAuOTQ5IDEwLjk0OSAxMiA2IDcuMDUxIDEuMDUxIDEyIDAgMTAuOTQ5IDQuOTQ5IDYgMCAxLjA1MSAxLjA1MSAwIDYgNC45NDkgMTAuOTQ5IDAgMTIgMS4wNTEgNy4wNTEgNnoiLz4KICAgIDwvZGVmcz4KICAgIDx1c2UgZmlsbD0iIzAwMCIgZmlsbC1vcGFjaXR5PSIuNCIgZmlsbC1ydWxlPSJldmVub2RkIiB4bGluazpocmVmPSIjYSIvPgo8L3N2Zz4K') no-repeat 50% 50%;
    }

    &__heading {
        font-size: 16px;
        font-weight: 600;
        color: #4a4a4a;
        line-height: 1.5;
        margin-bottom: 15px;
    }

    &__radios {
        margin-bottom: 45px;
        padding: 0 10px;
    }

    &__radio {
        > div {
            align-items: center;
        }
    }

    &__radio-label {
        font-weight: normal;
        font-family: $font-stack---open-sans;
        display: flex;
        align-items: center;

        > span {
            min-width: 200px;
        }
    }

    &__select {
        font-family: $font-stack---open-sans;
        width: 100% !important;
        max-width: 250px;
        font-size: 14px !important;
        font-weight: 600;
        color: #4a4a4a !important;

        div {
            font-family: $font-stack---open-sans;
            color: #4a4a4a !important;
        }

        hr {
            opacity: 0.12;
        }
    }

    &__menu-item {
        max-width: 250px;
        div {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    label {
        font-size: 14px;
        width: calc(100% - 40px) !important;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.51) !important;
    }

    &__description {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 30px;
        line-height: 1.38;
    }

    &__buttons {
        background-color: rgba(243, 243, 243, 0.74);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 22px 30px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;

        button {
            font-size: 14px;
            font-weight: 500;
            color: #1247F6;
            line-height: 16px;
            border-radius: 3px;
            padding: 5px 25px;
            background: transparent;
            border: none;
            text-transform: uppercase;
            transition: all 0.3s;

            &.blue {
                color: #fff;
                background: #1247F6;
            }

            @include bp(tab) {
                padding: 0 25px;
                line-height: 36px;
            }
        }
    }

    &__exporting {
        position: fixed;
        bottom: 0;
        border-radius: 4px;
        left: 0;
        border: solid 2px #fff;
        width: 100vw;
        background-color: #292A2D;
        box-shadow: 0 2px 12px 0 rgba(201, 201, 201, 0.5);
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        line-height: 16px;
        padding: 20px 17px;
        z-index: 1501;

        button {
            color: #00a0fc;
            border: none;
            background: transparent;
            float: right;
            text-transform: uppercase;
        }

        .progressPopup {
            font-size: 12px;
            text-transform: uppercase;
            margin-top: 7px;

            svg {
                margin-right: 6px;
                vertical-align: text-bottom;
            }
        }

        @include bp(tab) {
            left: 25px;
            bottom: 40px;
            width: 370px;
            border-radius: 3px;
        }
    }
}

/* tooltip */
.tooltip_white {
    width: 270px;
    padding: 16px !important;
    border-radius: 3px;
    background-color: #FFFFFF;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    pointer-events: auto !important;
    font-weight: 400;
    font-size: 14px !important;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);;
    white-space: normal;
    z-index: 10000 !important;

    &.show {
        opacity: 1 !important;
    }

    &:hover {
        visibility: visible !important;
        opacity: 1 !important;
    }

    &::after {
        border-top: 10px solid transparent !important;
        border-bottom: 10px solid transparent !important;
        margin-top: -10px !important;
    }

    &.place-right {
        margin-left: 20px !important;

        &::after {
            left: -10px !important;
            border-right-width: 10px !important;
        }
    }

    &.place-left {
        margin-left: -20px !important;

        &::after {
            right: -10px !important;
            border-left-width: 10px !important;
        }
    }

    @media screen and (max-width: 991px) {
        &.hide-mob {
            display: none !important;
        }
    }

}
