/*------------------------------------*\
        #Headings
\*------------------------------------*/
/* interview */
.c-heading-interview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 8px 0 8px 10px;
    margin-top: 10px;
    min-height: 50px;
    line-height: 16px;
    color: #fff;
    border-radius: 2px;
    font-weight: 600;
    cursor: pointer;
    background-color: #00283f;

    button {
        padding: 0 !important;
        width: 24px !important;
        height: 24px !important;
        vertical-align: bottom;
        margin-left: 3px;
    }
    svg {color: #fff !important;}

    &--yellow {
        background-color: $yellow;
    }

    &--black {
        background-color: rgba(#000, .5);
    }
}

.c-reg-step-dialog {
    position: relative;
    z-index: 1000;
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
        display: block;

        > div {
            margin-right: 50px;
        }

        .close-icon {
            position: absolute !important;
            right: 5px;
            top: 10px;
        }
    }
}
