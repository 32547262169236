

html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 14px;
    font-family: "Open Sans", "Work Sans", Helvetica, Arial, sans-serif;
}

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.wrap {
    background: #f5f5f5;
    min-height: 100%;
    position: relative;
    @media screen and (max-width: 991px) {
        min-height: calc(100vh - 196px);
    }
    @media screen and (min-width: 991px) {
        min-height: calc(100vh - 136px);
    }
}

body {
    background-color: $grey-f5;
    color: $font-color;
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

a {
    &:hover,
    &:focus,
    &:active  {text-decoration: none;}
}

button {
    &:focus {outline: 0;}
}

p {margin: 0 0 16px;}

.form-control:focus {
    box-shadow: none;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 400;
}

h1 {font-size: 28px;}
h2 {font-size: 24px;}
h3 {font-size: 22px;}
h5 {font-size: 18px;}
h6 {font-size: 16px;}

input:invalid {
    box-shadow: none;
}

hr {
    border-top: 1px solid rgba(#000, .2);

    &.c-hr--light {
        border-top: 1px solid rgba(#000, .5);
        opacity: .3;
    }

    &.c-hr--double {
        border-top: 2px solid rgba(#000, .2);
    }
}

::-ms-clear { display: none; }
::-ms-reveal  { display: none; }

