.react-tel-input {
  padding: 9px 12px;
  input[type=tel] {
    width: 100% !important;
    border: none !important;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: none;
    color: #292A2D;
    font-weight: 600;
    padding-left: 50px !important;
    font-size: 14px !important;
    line-height: 1.2 !important;
    font-family: "Open Sans", "Work Sans", Helvetica, Arial, sans-serif;
  }
  .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
    top: 0 !important;
    bottom: 0 !important;
    margin-top: auto;
    margin-bottom: auto;
    height: 60%;
  }

  .selected-flag {
    opacity: 1;
    padding: 0 0 0 4px;
    .selected-flag:focus, selected-flag:active {
      opacity: 1;
    }
    .flag {
      .arrow {
        display: none;
      }
      &:after {
        position: absolute;
        font-family: 'icomoon-vervoe';
        font-size: 10px;
        content: "\e60e";
        color: #2e3655;
        margin-left: 23px;
        display: inline-block;
        line-height: 1.2;
      }
    }
  }
}
