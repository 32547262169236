@charset "UTF-8";

@font-face {
  font-family: 'Proximanova-Extrabold';
  src: url(../fonts/proximanova-extrabld/proximanova-extrabld.ttf);
}

@import "itcss/0_settings/colors";
@import "itcss/0_settings/vars";
@import "itcss/0_settings/fonts";

@import "itcss/1_tools/mixins";

@import "itcss/3_generic/icomoon";
@import "itcss/3_generic/normalize";
@import "itcss/3_generic/reset";

@import "itcss/4_theme/colors";

@import "itcss/5_components/anchors";
@import "itcss/5_components/board";
@import "itcss/5_components/buttons";
@import "itcss/5_components/cards";
@import "itcss/5_components/createscripts";
@import "itcss/5_components/form-parts";
@import "itcss/5_components/header";
@import "itcss/5_components/headings";
@import "itcss/5_components/images";
@import "itcss/5_components/intro";
@import "itcss/5_components/lists";
@import "itcss/5_components/marketcards";
@import "itcss/5_components/navbars";
@import "itcss/5_components/others";
@import "itcss/5_components/popover";
@import "itcss/5_components/batch-actions";
@import "itcss/5_components/settings";

@import "itcss/6_objects/mediablocks";
@import "itcss/6_objects/layouts";
@import "itcss/6_objects/tables";

@import "itcss/2_utils/borders";
@import "itcss/2_utils/displays";
@import "itcss/2_utils/floats";
@import "itcss/2_utils/heights";
@import "itcss/2_utils/margins";
@import "itcss/2_utils/others";
@import "itcss/2_utils/paddings";
@import "itcss/2_utils/positions";
@import "itcss/2_utils/texts";
@import "itcss/2_utils/widths";

@import "itcss/7_vendor/aos";
@import "itcss/7_vendor/bootstrap.grid";
@import "itcss/7_vendor/bootstrap.tooltip";
@import "itcss/7_vendor/hamburger";
