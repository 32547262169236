//  -------------------------------------------------------
//  #Z-index utilities
//    dependencies: $z-indexes map in 0_settings/_vars.scss
//  -------------------------------------------------------

@each $z-value, $value in $z-indexes {
    %u-z--#{$z-value}, .u-z--#{$z-value} {
        z-index: $value;
    }
}

//  ------------------------------------
//  #Overflow utilities
//  ------------------------------------

%u-ovf--h, .u-ovf--h {overflow: hidden !important;}
%u-ovf--v, .u-ovf--v {overflow: visible !important;}
%u-ovf--a, .u-ovf--a {overflow: auto !important;}

//  ------------------------------------
//  #Visual utilities
//  ------------------------------------

%u-rounded, .u-rounded         {border-radius: $base-border-radius;}
%u-rounded-alt, .u-rounded-alt {border-radius: $base-border-radius-alt;}
%u-circle, .u-circle           {border-radius: 50% !important;}
%u-shadow, .u-shadow           {box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.13) !important;}
%u-muted-0, .u-muted-0         {opacity: 0 !important;}
%u-muted-2, .u-muted-2         {opacity: .2 !important;}
%u-muted-5, .u-muted-5         {opacity: .5 !important;}
%u-muted-6, .u-muted-6         {opacity: .6 !important;}
%u-muted-7, .u-muted-7         {opacity: .7 !important;}
%u-muted-8, .u-muted-8         {opacity: .8 !important;}


//  ------------------------------------
//  no animation flicker in webkit
//  ------------------------------------

%u-noflicker {
    transform-style: preserve-3d;
}

//  ------------------------------------
//  #Vertical alignment utilities
//  ------------------------------------

%u-va--top, .u-va--top {
    vertical-align: top !important;
}
%u-va--mid, .u-va--mid {
    vertical-align: middle !important;
}
%u-va--btm, .u-va--btm {
    vertical-align: bottom !important;
}


/*------------------------------------*\
    #Contain floats utilities (clearfix)
\*------------------------------------*/
/* clear elements */
%u-clear, .u-clear {clear: both !important;}


//  ------------------------------------
//  #Cursor utilities
//  ------------------------------------
.u-pe--none {pointer-events: none;}
.u-cursor--d {cursor: default !important;}
.u-cursor--p {cursor: pointer !important;}
.u-cursor--g {cursor: grab !important;}




