.wrapper {
  padding: 45px 15px;
  border-radius: 4px;
  font-size: 12px;
  background-color: #282b34;
  position: relative;
}

.listWrapper {
  padding: 15px 30px;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #282b34;
}

.languageWrapper {
  background-color: black;
  font-size: 9.6px;
  position: absolute;
  right: 20px;
  z-index: 1000;
  top: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: 0.1px;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  padding: 2px 12px;
  border-radius: 10px;
}

.countWrapper {
  position: absolute;
  bottom: 20px;
  left: 33px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
