//  ------------------------------------
//  #Position-type utilities
//  ------------------------------------

%u-pos--fixed, .u-pos--fixed       {position: fixed !important;}
%u-pos--relative, .u-pos--relative {position: relative !important;}
%u-pos--absolute, .u-pos--absolute {position: absolute !important;}
%u-pos--static, .u-pos--static     {position: static !important;}
%u-pos--overlay, .u-pos--overlay  {
    position : absolute !important;
    margin   : auto !important;
    top      : 0 !important;
    right    : 0 !important;
    bottom   : 0 !important;
    left     : 0 !important;

    &-dark {
        position : fixed !important;
        background: rgba(#000, .4);
        z-index: 100;
    }
}


//old classes used on landing page that need to be replaced by new ones from utils/_others.scss
// Vertical alignment
.va--t          {vertical-align: top;}
.va--b          {vertical-align: bottom;}
.va--base       {vertical-align: baseline;}
.va--middle     {vertical-align: middle !important;}
.va--tt         {vertical-align: text-top}
.va--s          {vertical-align: super;}


/* position top right */
.u-pos--atr {
    position: absolute !important;
    top: 0;
    right: 0;
}
/* position bottom right */
.u-pos--abr {
    position: absolute !important;
    bottom: 0;
    right: 0;
}
