//  ------------------------------------
//  #Sizes
//  ------------------------------------

$base-font-size       : 14px;
$base-spacing-unit    : 6px;
$base-border-radius   : 2px;
$base-transition-time : .075s;

//  ------------------------------------
//  #Jobseeker pages base values
//  ------------------------------------
$base-border-radius-alt   : 4px;


//  ------------------------------------
//  #Text sizes
//  ------------------------------------
$text-sizes: (
  "0"     : 0,
  "8"     : 8px,
  "9"     : 9px,
  "10"    : 10px,
  "11"    : 11px,
  "12"    : 12px,
  "13"    : 13px,
  "14"    : 14px,
  "15"    : 15px,
  "16"    : 16px,
  "18"    : 18px,
  "20"    : 20px,
  "21"    : 21px,
  "22"    : 22px,
  "24"    : 24px,
  "26"    : 26px,
  "28"    : 28px,
  "30"    : 30px,
  "32"    : 32px,
  "34"    : 34px,
  "36"    : 36px,
  "38"    : 38px,
  "42"    : 42px,
  "44"    : 44px,
  "48"    : 48px,
  "60"    : 60px,
  "62"    : 62px,
  "70"    : 70px,
);



//  ------------------------------------
//  #z-indexes sass map
//  ------------------------------------
$z-indexes: (
    '10001'  : 10001,
    '100'   : 100,
    '50'    : 50,
    '10'    : 10,
    '1'     : 1,
    '0'     : 0,
    '-1'    : -1,
);


/*------------------------------------*\
    #Fonts
\*------------------------------------*/
$font-stack--sans     : "Work Sans", Helvetica, Arial, sans-serif;
$font-stack---open-sans : 'Open Sans', 'Work Sans', Helvetica, Arial, sans-serif;
$font-stack--serif    : Georgia, "Times New Roman", Times, serif;
$font-stack--mono     : Monaco, Consolas, "Lucida Console", monospace;
$font-stack--proximanova: 'Proximanova', 'Open Sans';
$font-stack--proximanova-extrabld: 'Proximanova Extrabld', 'Open Sans';
