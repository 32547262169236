//  ------------------------------------
//   #Text colors
//  dependencies: $ui-colors map in 0_settings/_colors.scss
//  ------------------------------------
@each $colorname, $color in $ui-colors {
    %t-txt--#{$colorname}, .t-txt--#{$colorname} {
        color: $color !important;
    }
}

//  ------------------------------------
//   #Background colors
//  dependencies: $ui-colors map in 0_settings/_colors.scss
//  ------------------------------------
@each $colorname, $color in $ui-colors {
    %t-bgc--#{$colorname}, .t-bgc--#{$colorname} {
        background-color: $color !important;
    }
}

%t-bgc--transp, .t-bgc--transp {background-color: transparent !important;}