/*------------------------------------*\
    #Default profile image
\*------------------------------------*/
.c-img-placeholder {
    background: $blue;
    color: #fff;
    font-size: 19px;
    width: 100%;
    height: 100%;

    &--circle {
        border-radius: 50%;
    }
}

.c-img-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*------------------------------------*\
    #Circled image
\*------------------------------------*/

.c-img--circle {
    border-radius: 50%;
    position: relative;
    z-index: 1;
    overflow: auto;
}

/*------------------------------------
    #rectangle
------------------------------------*/
.c-rectangle {border-radius: 0 !important;}
