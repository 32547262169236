/*------------------------------------*\
    #Intro
\*------------------------------------*/
.c-intro {
    background: #393939 url("https://vervoe-live.s3.amazonaws.com/assets/0001/03/hero-mobile.jpg") no-repeat 50% 50%;
    background-size: cover;

    @include bp(lap) {
        background: #393939 url("https://vervoe-live.s3.amazonaws.com/assets/0001/03/hero.jpg") no-repeat 50% 50%;
        background-attachment: fixed;
    }

    .container {
        margin-top: 100px;
        @include bp(lap) {
            margin-top: 120px;
        }
    }

    /* h1 */
    &__h1 {
        color: #fff;
        line-height: 1.1;
        margin-bottom: 30px;
        font-size: responsive 36px 62px;
        font-range: 320px 1280px;
        font-weight: bold;
    }

    /* paragraph */
    &__pg {
        color: #fff;
        font-size: responsive 22px 32px;
        font-range: 320px 1280px;
        text-align: center;
        margin: 30px auto;

        span {
            display: block;
            text-align: center;
        }

        @include bp(lap) {
            margin: 40px auto 60px;

            span {display: inline;}
        }
    }

    /* pricing intro */
    &--pricing {
        padding-top: 200px;
        padding-bottom: 60px;
        background-image: url("https://vervoe-live.s3.amazonaws.com/assets/0001/03/hero-pricing-mobile.jpg");
        background-size: cover;

        @include bp(lap) {
            padding-bottom: 260px;
            background-image: url("https://vervoe-live.s3.amazonaws.com/assets/0001/03/hero-pricing.jpg");
            background-size: cover;
        }

        .container {
            position: relative;
            z-index: 2;
        }
    }

}