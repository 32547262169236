/*------------------------------------*\
    #Header
\*------------------------------------*/
.c-homeheader {
    position: fixed;
    width: 100%;
    left: 0;
    background: transparent none;
    transition: .4s;
    z-index: 1000;

    &.is-stuck {
        background: #fff;
        box-shadow: 0 0 10px rgba(#000, .2);
    }

    /* Container */
    &__container {
        position: relative;
        line-height: 0.5;
        border-bottom: 1px solid rgba(#000, .5);
        text-align: center;
        padding-top: 40px;
        padding-bottom: 40px;
        transition: .4s;

        .is-stuck & {border-bottom: 0;}

        button {
            transition: .4s;
            position: absolute;
            top: 44px;
            left: 14px;
            border: 0;
            background: transparent none;
            color: #fff;
            font-size: 16px;

            &:hover {opacity: .4;}

            .is-stuck & {
                top: 29px;
                color: #333;
            }

            @include bp(lap) {
                display: none;
            }
        }

        .is-stuck & {
            padding-top: 25px;
            padding-bottom: 25px;

        }
    }

    &__logo {

        path {
            fill: #fff;
            transition: .4s;

            .is-stuck & {
                fill: #222;
            }
        }
    }



    /* navigation */
    &__nav {
        font-size: 0;
        display: none;
        flex-direction: column;
        background: #333;
        transition: .4s;

        .is-stuck & {
            background: none;
        }

        @include bp(lap) {
            position: absolute;
            top: 0;
            height: 100%;
            flex-direction: row;
            display: flex !important;
            background: none;
        }

        li {
            font-size: 14px;
            display: block;

            &.is-active a {color: $blue;}

            @include bp(lap) {
                display: flex;
                align-items: center;
            }

        }
        a {
            transition: .4s;
            padding: 14px 18px;
            color: #fff;
            text-align: left;
            display: block;

            &:hover {color: $blue;}

            .is-stuck & {
                color: #222;

                &:hover {color: $blue;}
            }

            &.c-homeheader__loginlink {
                background: rgba(#fff, .1);
                border-radius: 50em;

                &:hover {
                    background: rgba(#fff, .3);
                    color: #fff;
                    opacity: 1;
                }

                .is-stuck & {
                    background: $blue;
                    color: #fff;

                    &:hover {background: $blue-dark;}
                }
            }

        }
    }

    &__nav--left {
        margin-top: 30px;
        padding: 15px 15px 0;

        @include bp(lap) {
            margin-top: 0;
            padding: 0;
            position: absolute;
            left: 0;
        }

    }
    &__nav--right {
        padding: 0 15px 15px;

        @include bp(lap) {
            position: absolute;
            right: 0;
            padding: 0;
        }
    }
}

