//  ------------------------------------
//  #Display-type utilities
//  ------------------------------------

%u-dsp--b , .u-dsp--b            {display: block !important;}
%u-dsp--i , .u-dsp--i            {display: inline !important;}
%u-dsp--ib, .u-dsp--ib           {display: inline-block !important;}
%u-dsp--if, .u-dsp--if           {display: inline-flex !important;}
%u-dsp--t , .u-dsp--t            {display: table !important;}
%u-dsp--tc, .u-dsp--tc           {display: table-cell !important;}
%u-dsp--tr, .u-dsp--tr           {display: table-row !important;}
%u-dsp--n , .u-dsp--n            {display: none; }  //without !important so that it can be used for js toggling  //(use .hide when !important needed)
%u-dsp--f , .u-dsp--f            {display: flex !important; }
%u-dsp--f-tab , .u-dsp--f-tab    {
    @include bp(tab) {
        display: flex;
    }
}
%u-dsp--f-lap , .u-dsp--f-lap    {
    @include bp(lap) {
        display: flex;
    }
}

//  ------------------------------------
//  #Center block element
//  ------------------------------------
%u-dsp--center , .u-dsp--center  {margin: 0 auto !important;}

//  ---------------------------------------------------------
//  #Center content vertically&horizontally inside an element
//  ---------------------------------------------------------
%u-dsp--centered , .u-dsp--centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

//  ---------------------------------------------------------------------------
//  #Center content vertically and distribute elements evenly inside an element
//  ---------------------------------------------------------------------------
%u-dsp--distribute , .u-dsp--distribute {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

//  ------------------------------------
// justify-content
//  ------------------------------------
%u-jc--center, .u-jc--center { justify-content: center !important;}
%u-jc--end, .u-jc--end    { justify-content: flex-end !important;}
%u-jc--start, .u-jc--start  {justify-content: flex-start !important;}
%u-jc--sb, .u-jc--sb         { justify-content: space-between !important;;}

//  ------------------------------------
// align-items
//  ------------------------------------
%u-ai--center, .u-ai--center {align-items: center;}
%u-ai--baseline, .u-ai--baseline {align-items: baseline !important;}
%u-ai--end, .u-ai--end       {align-items: flex-end !important;}
%u-ai--start, .u-ai--start       {align-items: flex-start!important;}

//  ------------------------------------
// align-self
//  ------------------------------------
.u-as--center {align-self: center;}
.u-as--fs     {align-self: flex-start;}
//  ------------------------------------
// flex-wrap
//  ------------------------------------
%u-fw--wrap, .u-fw--wrap     { flex-wrap: wrap;}

//  ------------------------------------
// flex-direction
//  ------------------------------------
.u-fdir--column    { flex-direction: column !important;}
.u-fdir--row        { flex-direction: row !important;}

.u-flex-grow0  {flex-grow: 0 !important;}

.u-fxexpand1  {flex: 1;}
.u-fxexpand   {flex: 9;}

//  ------------------------------------
// flex-direction
//  ------------------------------------
.f-dir--column    { flex-direction: column;}
.f-dir--row       { flex-direction: row;}
